'use client';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import { review, shop } from "@/types/graphql";
import { getColorVariable } from "@/utils/color";
import { stdComponentDataList } from "@/utils/std-component-data";
import { CSSVariables } from "@/utils/types";
import { useEffect, useState } from "react";
import { DynamicProperty } from "@/utils/dynamic-properties";
import { getValue } from "@/utils/styles";
import { Button, ButtonSize, ButtonStyle } from "@/ui-components/button";
import Rating from "@/ui-components/rating";
import FAIcon from "@/base-components/icons";
import 'swiper/css';

//-->dynamicData
let dynamicData = {
  uid: "legacy-template-lite-1-reviews",
  adapter: "reviews",
  data: {

    // Content
    "title": {
      sort: 1,
      name: 'Title',
      type: 'text',
      value: 'Our Reviews',
      section: 'content',
    },
    "reviews_number": {
      sort: 2,
      name: 'Reviews Number',
      type: 'number',
      value: 8,
      min_num: 3,
      max_num: 16,
      section: 'content'
    },
    "delay": {
      sort: 3,
      name: 'Delay',
      type: 'number',
      value: 12,
      min_num: 4,
      max_num: 32,
      section: 'content'
    },

    "primary_link_text": {
      sort: 4,
      name: 'Primary Link Text',
      type: 'text',
      value: 'Request Appointment',
      section: 'content',
    },
    "primary_link": {
      sort: 5,
      name: 'Primary Link',
      type: 'text',
      value: '#make-appointment',
      section: 'content',
    },
    "secondary_link_text": {
      sort: 6,
      name: 'Secondary Link Text',
      type: 'text',
      value: 'More From Our Customers',
      section: 'content',
    },
    "secondary_link": {
      sort: 7,
      name: 'Secondary Link',
      type: 'text',
      value: 'reviews',
      section: 'content',
    },

    // UI
    "section_bg_color": {
      sort: 1,
      name: 'Section Background Color',
      type: 'color',
      value: 'unset',
      section: 'ui',
    },
    "section_bg_color_tint": stdComponentDataList('slider', 2, 'Section Background Color Tint', 'Section Background Color Tint', '', 500, 'ui', 'tints'),
    "section_title_color": {
      sort: 3,
      name: 'Section Title Color',
      type: 'color',
      value: 'primary',
      section: 'ui',
    },
    "section_title_color_tint": stdComponentDataList('slider', 4, 'Section Title Color Tint', 'Section Title Color Tint', '', 500, 'ui', 'tints'),

    "review_style": {
      sort: 5,
      name: 'Review Style',
      type: 'radio',
      value: 'outlined',
      options: [
        {
          'display': "Outlined",
          'value': 'outlined'
        },
        {
          'display': "Filled",
          'value': 'filled'
        }
      ],
      section: 'ui'
    },
    "review_bg_color": {
      sort: 6,
      name: 'Review Background Color',
      type: 'color',
      value: 'neutral',
      section: 'ui',
    },
    "review_bg_color_tint": stdComponentDataList('slider', 7, 'Review Background Color Tint', 'Review Background Color Tint', '', 100, 'ui', 'tints'),
    "review_text_color": {
      sort: 8,
      name: 'Review Text Color',
      type: 'color',
      value: 'neutral',
      section: 'ui',
    },
    "review_text_color_tint": stdComponentDataList('slider', 9, 'Review Text Color Tint', 'Review Color Tint', '', 600, 'ui', 'tints'),

    "links_color": {
      sort: 10,
      name: 'Links Color',
      type: 'color',
      value: 'primary',
      section: 'ui',
    },
    "links_color_tint": stdComponentDataList('slider', 11, 'Links Color Tint', 'Links Color Tint', '', 500, 'ui', 'tints'),

    "padding_top": stdComponentDataList('slider', 12, 'Top Padding', 'Top Padding', '', 16),
    "padding_bottom": stdComponentDataList('slider', 13, 'Bottom Padding', 'Bottom Padding', '', 16),
  },
};
//<--dynamicData

export default function Reviews({ data, config }: { data: shop, config: any })
{

  config?.uid && (dynamicData = config);

  const section_bg_color: CSSVariables = {
    [DynamicProperty.Color.Bg.Default]: getColorVariable(
      dynamicData?.data?.section_bg_color?.value,
      dynamicData?.data?.section_bg_color_tint?.value),
  }

  const section_paddings: CSSVariables = {
    [DynamicProperty.Padding.Top.Default]: getValue(dynamicData?.data?.padding_top?.value),
    [DynamicProperty.Padding.Bottom.Default]: getValue(dynamicData?.data?.padding_bottom?.value),
  }

  const section_title_color: CSSVariables = {
    [DynamicProperty.Color.Text.Default]: getColorVariable(
      dynamicData?.data?.section_title_color?.value,
      dynamicData?.data?.section_title_color_tint?.value)
  }

  const review_bg_color: CSSVariables = {
    [DynamicProperty.Color.Bg.Default]: getColorVariable(
      dynamicData?.data?.review_bg_color?.value,
      dynamicData?.data?.review_bg_color_tint?.value),
  }

  const review_border_color: CSSVariables = {
    [DynamicProperty.Color.Border.Default]: getColorVariable(
      dynamicData?.data?.review_bg_color?.value,
      dynamicData?.data?.review_bg_color_tint?.value)
  }

  const review_title_color: CSSVariables = {
    [DynamicProperty.Color.Text.Default]: getColorVariable(
      dynamicData?.data?.review_text_color?.value,
      Number(dynamicData?.data?.review_text_color?.value) + 300)
  }

  const review_text_color: CSSVariables = {
    [DynamicProperty.Color.Text.Default]: getColorVariable(
      dynamicData?.data?.review_text_color?.value,
      dynamicData?.data?.review_text_color_tint?.value)
  }

  const review_style: any = dynamicData?.data?.review_style?.value === 'filled'
    ?
    'bg-(--dynamic-bg)'
    :
    'border-(--dynamic-border) border'

  // Array of selected reviews
  const selected_reviews: review[] = data?.reviews?.filter((review: review) =>
  {
    return review.selected;
  });

  // Array with random Google reviews with 5* rating. Used by default
  const [random_google_reviews, setRandomGoogleReviews] = useState<review[]>()
  useEffect(() =>
  {
    setRandomGoogleReviews(data?.reviews?.sort(() => Math.random() - 0.5).filter((review: review) =>
    {
      return review.rating === 5 && review.source_id === 1;
    }).slice(0, dynamicData?.data?.reviews_number?.value));
  }, [data]);

  // Array with random AV reviews with 5* rating. Used in case shop has no Google reviews 
  const [random_av_reviews, setAVReviews] = useState<review[]>()
  useEffect(() =>
  {
    setAVReviews(data?.reviews?.sort(() => Math.random() - 0.5).filter((review: review) =>
    {
      return review.rating === 5 && review.source_id === 2;
    }).slice(0, dynamicData?.data?.reviews_number?.value));
  }, [data]);

  const reviews: review[] = selected_reviews && selected_reviews?.length > 0
    ?
    selected_reviews
    :
    random_google_reviews && random_google_reviews?.length > 0
      ?
      random_google_reviews
      :
      random_av_reviews && random_av_reviews?.length > 0
        ?
        random_av_reviews
        :
        []

  return (
    <section
      id={dynamicData?.uid}
      className={`bg-(--dynamic-bg) overflow-hidden`}
      style={{ ...section_bg_color }}>

      <div
        className={`pt-(--dynamic-pt) pb-(--dynamic-pb) relative pl-4 sm:pl-6 xl:px-0 mx-auto max-w-(--breakpoint-xl)`}
        style={{ ...section_paddings }}>

        {/* Title */}
        {
          dynamicData?.data?.title?.value &&
          <h2 className={`text-(--dynamic-text) mb-10 font-bold capitalize tracking-tight`}
            style={{ ...section_title_color }}>

            {dynamicData?.data?.title?.value}

          </h2>
        }

        {/* Buttons desktop */}
        <div className="hidden md:flex absolute top-1/2 -mt-4 w-full mx-auto justify-between z-20">

          <button className="prev-button flex border border-primary-500 hover:border-primary-600 rounded-full -ml-16 p-4 hover:text-primary-600 duration-300 cursor-pointer">
            <span className="sr-only">Previous Slide</span>
            <FAIcon type="solid" nameIco='fa-chevron-left' customClass="leading-none h-4 w-4" />
          </button>

          <button className="next-button flex border border-primary-500 hover:border-primary-600 rounded-full -mr-16 p-4 hover:text-primary-600 duration-300 cursor-pointer">
            <span className="sr-only">Next Slide</span>
            <FAIcon type="solid" nameIco='fa-chevron-right' customClass="leading-none h-4 w-4" />
          </button>

        </div>

        <Swiper
          autoplay={{
            disableOnInteraction: false,
            delay: dynamicData?.data?.delay?.value > 0
              ? (dynamicData?.data?.delay?.value ?? 12) * 1000
              : 12000,
            pauseOnMouseEnter: true,
          }}
          navigation={{
            nextEl: '.next-button',
            prevEl: '.prev-button',
          }}
          modules={[Autoplay, Navigation]}
          spaceBetween={12}
          slidesPerView={1.2}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 24
            },
          }}
          loop={true}>

          <div className="swiper-wrapper">
            {
              reviews?.map((review: review, idx: number) => 
              {
                return (
                  <SwiperSlide key={idx}>

                    <blockquote
                      key={idx}
                      className={`${review_style}
												flex flex-col justify-between h-80 px-6 py-8 rounded`}
                      style={{ ...review_bg_color, ...review_text_color, ...review_border_color }}>

                      <div className="mb-2 md:mb-6">

                        <Rating rating={review?.rating ?? 5} color={''} />

                        <div className="mt-4">
                          <p className="mb-2 line-clamp-5">

                            {review?.content || '—'}

                          </p>
                        </div>

                      </div>

                      <footer
                        className={`text-(--dynamic-text) capitalize text-right font-bold`}
                        style={{ ...review_title_color }}>

                        {/* Author */}
                        {review?.author_name?.toLowerCase() || "Unknown Author"}

                        {/* Location */}
                        {
                          review?.location &&
                          <span className="font-normal">

                            {' from ' + review?.location}

                          </span>
                        }

                      </footer>

                    </blockquote>

                  </SwiperSlide>
                )
              })
            }
          </div>

        </Swiper>

        {/* Links */}
        {
          (dynamicData?.data?.primary_link_text?.value || dynamicData?.data?.secondary_link_text?.value) &&
          <div className="flex flex-wrap w-full space-y-4 md:space-y-0 md:space-x-4 md:w-auto text-center mt-12">

            {/* Primary link */}
            {
              dynamicData?.data?.primary_link_text?.value &&
              <Button
                id={dynamicData?.uid + '-primary-link'}
                href={dynamicData?.data?.primary_link?.value}
                style={ButtonStyle.filled}
                size={ButtonSize.regular}
                color={dynamicData?.data?.links_color?.value}
                tint={dynamicData?.data?.links_color_tint?.value}>

                {dynamicData?.data?.primary_link_text?.value || 'Full List of Services'}

              </Button>
            }

            {/* Secondary link */}
            {
              dynamicData?.data?.secondary_link_text?.value &&
              <Button
                id={dynamicData?.uid + '-secondary-link'}
                href={dynamicData?.data?.secondary_link?.value}
                style={ButtonStyle.outlined}
                size={ButtonSize.regular}
                color={dynamicData?.data?.links_color?.value}
                tint={dynamicData?.data?.links_color_tint?.value}>

                {dynamicData?.data?.secondary_link_text?.value || 'Request Appointment'}

              </Button>
            }

          </div>
        }

      </div>

    </section>

  )
}
