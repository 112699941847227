// https://www.hyperui.dev/components/marketing/testimonials#component-1
"use client";

import FAIcon from "@/base-components/icons";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import { review } from "@/types/graphql";
import 'swiper/css';

export default function Reviews({ data }: any)
{

  // Array of selected reviews
  let selected_reviews = data?.reviews?.filter((review: review) =>
  {
    return review.selected;
  });

  return (
    <section className="max-w-(--breakpoint-xl) mx-auto py-28">

      <div className="grid md:grid-cols-5">

        {/* Title */}
        <div className="col-span-2 md:pr-6">

          <h2 className="font-bold text-3xl sm:text-4xl capitalize mb-4">
            Our Customers Love Us
          </h2>

          <p className="text-neutral-600 lg:mb-24">
            Here iss a few of their latest reviews
          </p>

          {/* CTA */}
          <div className="flex flex-wrap gap-4 text-center">
            <a href="/reviews"
              className="inline-flex items-center w-full rounded-lg bg-primary-500 px-8 py-3 font-medium text-white shadow hover:bg-primary-700 focus:outline-hidden focus:ring-3 active:bg-primary-500 sm:w-auto cursor-pointer">
              Read our Reviews
              <FAIcon type="solid" nameIco='fa-arrow-right' customClass="text-white ml-2" />
            </a>
          </div>

        </div>

        {/* Slider */}
        <div className="col-span-3">

          {/* Blockquote icon */}
          <FAIcon type="solid" nameIco='fa-quote-left' customClass="text-secondary-400 text-8xl sm:text-9xl capitalize -mt-8" />

          <Swiper
            autoplay={{
              disableOnInteraction: false,
              delay: 4000,
              pauseOnMouseEnter: true,
            }}
            modules={[Autoplay]}
            spaceBetween={0}
            slidesPerView={1}
            breakpoints={{
            }}
            loop={true}
          >

            {/* Reviews */}
            <div className="swiper-wrapper relative">
              {
                selected_reviews?.map((review: any, index: number) =>
                {
                  return (
                    <SwiperSlide key={index}>
                      <blockquote
                        className="flex flex-wrap overflow-hidden mx-4 md:mx-0"
                        key={index}>

                        <p className="w-full text-secondary-500">
                          {review?.content
                            ? review?.content
                            : '—'}
                        </p>

                        <div className="flex flex-col mt-8 w-full">

                          <h3 className="font-bold text-neutral-900 ml-auto">
                            {review.author_name
                              ? "— " + review.author_name
                              : "— Unknown Author"}
                          </h3>

                          <span className="text-sm text-neutral-400 ml-auto">
                            {review.date
                              ? new Date(review.date).toDateString()
                              : '—'}
                          </span>

                        </div>

                      </blockquote>
                    </SwiperSlide>
                  )
                })

              }
            </div>

          </Swiper>

        </div>

      </div>

    </section>
  )
}
