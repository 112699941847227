export function getColorVariable(color: string, tint: number, opacity?: number): string
{

	// Fallback if color isn't a valid string
	typeof color === 'string' || (color = 'unset');

	// Fallback if tint is invalid
	tint = Number(tint) || (tint = 500);

	// Clamp tint to Tailwind CSS range (50 to 950)
	tint = Math.max(50, Math.min(tint, 950));

	// Round to nearest 100
	(tint < 50 || tint > 950) && Math.round(tint / 100) * 100;

	// Add transparency if present
	const alpha = opacity ? `/ ${opacity / 100}` : ``;

	return color === 'unset'
		// Return white if color is unset
		? 'var(--color-white)'
		// Otherwise return the color and clumped tint
		: `rgb(from var(--clr-${color}-${tint}) r g b ${alpha})`;
}
