// @ts-nocheck

/*
you can use it the following way:
1. import it:
import FAIcon from '@/base-components/icons'

2. include it as this:
<FAIcon type="regular" nameIco='fa-folder-open' customClass="h1 p-5" />

*/

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as solid from '@fortawesome/free-solid-svg-icons'
import * as regular from '@fortawesome/free-regular-svg-icons'
import * as brands from '@fortawesome/free-brands-svg-icons'
import { config } from '@fortawesome/fontawesome-svg-core'
config.autoAddCss = false


const UpperFirst = (sentence: string) =>
{
    if (!sentence || (typeof sentence !== 'string')) return null
    return `${sentence.charAt(0).toUpperCase()}${sentence.slice(1)}`
}

const FAIcon = ({ type, customClass, nameIco, color, style }: any) =>
{
    if (!nameIco) return null
    const finalName: any = nameIco.split('-').map((cv: any, ind: any) => ind === 0 ? cv : UpperFirst(cv)).join('')
    let finalIcon = null;

    if (type == 'regular')
    {
        finalIcon = regular[finalName];
    } else if (type == 'solid')
    {
        finalIcon = solid[finalName]
    } else if (type == 'brands')
    {
        finalIcon = brands[finalName]
    }

    if (!finalIcon) return null
    return <FontAwesomeIcon icon={finalIcon} color={color} className={customClass || ''} style={style ?? {}} />
}

export default FAIcon