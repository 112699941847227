'use client';

import { review, shop } from "@/types/graphql";
import { stdComponentDataList } from "@/utils/std-component-data";
import { useState } from "react";

//-->dynamicData
let dynamicData = {
	uid: 'page-reviews-masonry',
	adapter: "reviews",
	data: {

		'reviews_number': {
			sort: 0,
			name: 'Reviews Number',
			type: 'number',
			value: 8,
			section: 'content'
		},

		// UI
		"divider": {
			sort: 1,
			name: 'Divider',
			type: 'checkbox',
			value: 'true',
			section: 'ui',
		},

		"padding_top": stdComponentDataList('slider', 2, 'Top Padding', 'Padding', '', 24),
		"padding_bottom": stdComponentDataList('slider', 3, 'Bottom Padding', 'Padding', '', 20),
	}
}
//<--dynamicData

export default function PageReviews({ data, config }: { data: shop, config: any })
{

	config?.uid && (dynamicData = config);

	const [state, setState] = useState('google');

	const av_reviews: review[] = data?.reviews?.filter((review: review) =>
	{
		return review?.source_id === 1;
	}).slice(0, dynamicData?.data?.reviews_number?.value);

	const google_reviews: review[] = data?.reviews?.filter((review: review) =>
	{
		return review?.source_id === 2;
	}).slice(0, dynamicData?.data?.reviews_number?.value);

	let reviews;

	switch (state)
	{
		case 'av': {
			reviews = av_reviews;
			break;
		}
		case 'google': {
			reviews = google_reviews;
			break;
		}
		default: {
			reviews = google_reviews;
			break;
		}
	}

	return (
		<section className="py-28">
			<div className="max-w-(--breakpoint-xl) mx-auto px-4 md:px-0">

				<div className="flex justify-between items-start">

					{/* title */}
					<div className="space-y-5">

						<h1 className="mb-6 text-3xl font-bold sm:text-4xl capitalize">
							Reviews
						</h1>

						{/* Divider */}
						{
							dynamicData?.data?.divider?.value === 'true' &&
							<div className={`flex w-full mt-8 mb-12`}>

								<div className={`bg-primary-500 w-16 h-1 rounded-full inline-flex`} />

							</div>
						}

					</div>

					{/* Tab buttons */}
					<div className="flex pt-2 overflow-x-auto overflow-y-hidden border-b border-neutral-200 whitespace-nowrap">

						<button className="inline-flex items-center h-10 px-4 -mb-px text-sm text-center text-primary-600 bg-transparent border-b-2 border-primary-500 sm:text-base whitespace-nowrap focus:outline-hidden">
							Reviews from Google
						</button>

						<button className="inline-flex items-center h-10 px-4 -mb-px text-sm text-center text-neutral-700 bg-transparent border-b-2 border-transparent sm:text-base whitespace-nowrap cursor-base focus:outline-hidden hover:border-neutral-400">
							Certified by AutoVitals
						</button>

					</div>

				</div>

				<ul className="gap-x-8 gap-y-10 mt-16 gap-8 columns-1 sm:columns-2 lg:columns-3 break-inside-avoid-column">
					{
						reviews?.map((review: review, idx: any) =>
						{
							return (
								<li
									key={idx}
									className="rounded overflow-hidden bg-neutral-50 w-full mx-auto mb-12 group sm:max-w-sm relative break-inside-avoid">
									<div className="p-6">

										<blockquote className="flex flex-wrap overflow-hidden mx-4 md:mx-0">

											<p className="w-full text-neutral-600">
												{
													review?.content
														? review?.content
														: '—'
												}
											</p>

											<div className="flex flex-col mt-8 w-full">

												<h3 className="font-bold ml-auto">
													{
														review.author_name
															? "— " + review.author_name
															: "— Unknown Author"
													}
												</h3>

												<span className="text-sm text-neutral-400 ml-auto">
													{
														review.date
															? new Date(review.date).toDateString()
															: '—'
													}
												</span>

											</div>

										</blockquote>
									</div>

								</li>
							)
						})
					}
				</ul>
			</div>
		</section>
	)
}