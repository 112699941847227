import { CSSVariables } from "@/utils/types"
import FAIcon from "@/base-components/icons"
import React from 'react'

interface MarkerProps
{
  className?: string
  style?: CSSVariables
  draggable: boolean
  lat: number
  lng: number
  markerId: string
}

const Marker = ({
  className,
  style,
  lat,
  lng,
  markerId,
  draggable,
  ...props
}: MarkerProps) =>
  (lat && lng) &&
  (
    <FAIcon type="solid" nameIco='fa-location-dot' customClass={className} style={style} />
  )

export default Marker