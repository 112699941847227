'use client';

import { review, shop } from "@/types/graphql";
import { Button, ButtonSize, ButtonStyle } from "@/ui-components/button";
import Rating from "@/ui-components/rating";
import { getColorVariable } from "@/utils/color";
import { DynamicProperty } from "@/utils/dynamic-properties";
import { stdComponentDataList } from "@/utils/std-component-data";
import { getValue } from "@/utils/styles";
import { CSSVariables } from "@/utils/types";
import Link from "next/link";
import { useEffect, useState } from "react";

//-->dynamicData
let dynamicData = {
	uid: "legacy-template-4-reviews",
	adapter: "reviews",
	data: {

		// Content
		"title": {
			sort: 1,
			name: 'Title',
			type: 'text',
			value: 'Our Reviews',
			section: 'content',
		},
		"reviews_number": {
			sort: 2,
			name: 'Reviews Number',
			type: 'radio',
			value: 3,
			options: [
				{
					'display': "3",
					'value': 3,
				},
				{
					'display': "6",
					'value': 6,
				},
			],
			section: 'content'
		},
		"primary_link_text": {
			sort: 3,
			name: 'Primary Link Text',
			type: 'text',
			value: 'Request Appointment',
			section: 'content',
		},
		"primary_link": {
			sort: 4,
			name: 'Primary Link',
			type: 'text',
			value: '#make-appointment',
			section: 'content',
		},
		"secondary_link_text": {
			sort: 5,
			name: 'Secondary Link Text',
			type: 'text',
			value: 'More From Our Customers',
			section: 'content',
		},
		"secondary_link": {
			sort: 6,
			name: 'Secondary Link',
			type: 'text',
			value: 'reviews',
			section: 'content',
		},

		// UI
		"section_bg_color": {
			sort: 1,
			name: 'Section Background Color',
			type: 'color',
			value: 'neutral',
			section: 'ui',
		},
		"section_bg_color_tint": stdComponentDataList('slider', 2, 'Section Background Color Tint', 'Section Background Color Tint', '', 500, 'ui', 'tints'),
		"section_text_color": {
			sort: 3,
			name: 'Section Text Color',
			type: 'color',
			value: 'unset',
			section: 'ui',
		},
		"section_text_color_tint": stdComponentDataList('slider', 4, 'Section Text Color Tint', 'Section Background Color Tint', '', 600, 'ui', 'tints'),

		"review_bg_color": {
			sort: 5,
			name: 'Review Background Color',
			type: 'color',
			value: 'unset',
			section: 'ui',
		},
		"review_bg_color_tint": stdComponentDataList('slider', 6, 'Review Background Color Tint', 'Review Color Tint', '', 50, 'ui', 'tints'),
		"review_text_color": {
			sort: 7,
			name: 'Review Text Color',
			type: 'color',
			value: 'neutral',
			section: 'ui',
		},
		"review_text_color_tint": stdComponentDataList('slider', 8, 'Review Text Color Tint', 'Review Color Tint', '', 600, 'ui', 'tints'),
		"review_shadow": {
			sort: 9,
			name: 'Review Shadow',
			type: 'checkbox',
			value: 'true',
			section: 'ui',
		},

		"primary_link_color": {
			sort: 10,
			name: 'Primary Link Color',
			type: 'color',
			value: 'primary',
			section: 'ui',
		},
		"primary_link_color_tint": stdComponentDataList('slider', 11, 'Primary Link Color Tint', 'Primary Link Text Color Tint', '', 500, 'ui', 'tints'),
		"secondary_link_color": {
			sort: 12,
			name: 'Secondary Link Color',
			type: 'color',
			value: 'unset',
			section: 'ui',
		},
		"secondary_link_color_tint": stdComponentDataList('slider', 13, 'Secondary Link Color Tint', 'Secondary Link Color Tint', '', 500, 'ui', 'tints'),

		"padding_top": stdComponentDataList('slider', 14, 'Top Padding', 'Padding', '', 24),
		"padding_bottom": stdComponentDataList('slider', 15, 'Bottom Padding', 'Padding', '', 20),
	},
};
//<--dynamicData

export default function Reviews({ data, config }: { data: shop, config: any })
{

	config?.uid && (dynamicData = config);

	const section_bg_color: CSSVariables = {
		[DynamicProperty.Color.Bg.Default]: getColorVariable(
			dynamicData?.data?.section_bg_color?.value,
			dynamicData?.data?.section_bg_color_tint?.value),
	}

	const section_title_color: CSSVariables = {
		[DynamicProperty.Color.Text.Default]: getColorVariable(
			dynamicData?.data?.section_text_color?.value,
			Number(dynamicData?.data?.section_text_color_tint?.value) + 300)
	}

	const review_bg_color: CSSVariables = {
		[DynamicProperty.Color.Bg.Default]: getColorVariable(
			dynamicData?.data?.review_bg_color?.value,
			dynamicData?.data?.review_bg_color_tint?.value),
	}

	const section_paddings: CSSVariables = {
		[DynamicProperty.Padding.Top.Default]: getValue(dynamicData?.data?.padding_top?.value),
		[DynamicProperty.Padding.Bottom.Default]: getValue(dynamicData?.data?.padding_bottom?.value),
	}

	// Array of selected reviews
	const selected_reviews: review[] = data?.reviews?.filter((review: review) =>
	{
		return review.selected;
	});

	// Array with random Google reviews with 5* rating. Used by default
	const [random_google_reviews, setRandomGoogleReviews] = useState<review[]>()
	useEffect(() =>
	{
		setRandomGoogleReviews(data?.reviews?.sort(() => Math.random() - 0.5).filter((review: review) =>
		{
			return review.rating === 5 && review.source_id === 1;
		}).slice(0, dynamicData?.data?.reviews_number?.value));
	}, [data]);

	// Array with random AV reviews with 5* rating. Used in case shop has no Google reviews 
	const [random_av_reviews, setAVReviews] = useState<review[]>()
	useEffect(() =>
	{
		setAVReviews(data?.reviews?.sort(() => Math.random() - 0.5).filter((review: review) =>
		{
			return review.rating === 5 && review.source_id === 2;
		}).slice(0, dynamicData?.data?.reviews_number?.value));
	}, [data]);

	const reviews: review[] = selected_reviews && selected_reviews?.length > 0
		?
		selected_reviews
		:
		random_google_reviews && random_google_reviews?.length > 0
			?
			random_google_reviews
			:
			random_av_reviews && random_av_reviews?.length > 0
				?
				random_av_reviews
				:
				[]

	return (
		<section
			id={dynamicData?.uid}
			className={`bg-(--dynamic-bg)`}
			style={{ ...section_bg_color }}>

			<div
				className={`pt-(--dynamic-pt) pb-(--dynamic-pb) px-4 sm:px-6 xl:px-0 mx-auto max-w-(--breakpoint-xl)`}
				style={{ ...section_paddings }}>

				{/* Title */}
				{
					dynamicData?.data?.title?.value &&
					<h2
						className={`text-h3 lg:text-h2 text-(--dynamic-text) font-black text-center uppercase tracking-wide mb-8`}
						style={{ ...section_title_color }}>

						{dynamicData?.data?.title?.value}

					</h2>
				}

				<ul className="grid gap-x-8 gap-y-12 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mb-12">
					{
						reviews?.map((review: review, idx: number) => (
							<li
								key={idx}
								className={`bg-(--dynamic-bg) relative flex h-full flex-col justify-between px-6 py-8 rounded`}
								style={{ ...review_bg_color }}>

								{/* Shadow */}
								{
									dynamicData?.data?.review_shadow?.value === 'true' &&
									<div className={`
										${dynamicData?.data?.section_bg_color?.value === 'unset' ||
											dynamicData?.data?.section_bg_color?.value === 'neutral'
											?
											'opacity-25'
											:
											'mix-blend-overlay'}
											absolute flex inset-0 rounded shadow`}
									/>
								}

								<div className="relative mb-2 md:mb-6">

									<Rating rating={review?.rating || 5} color={''} />

									<div className="mt-4">
										<p className="mb-2 line-clamp-5">

											{review?.content || '—'}

										</p>
									</div>

								</div>

								<footer className="relative capitalize text-right font-bold">

									{/* Author */}
									{review?.author_name?.toLowerCase() || "Unknown Author"}

									{/* Location */}
									{
										review?.location &&
										<span className="font-normal">

											{' from ' + review?.location}

										</span>
									}

								</footer>

							</li>

						))
					}
				</ul>

				<div className="flex flex-wrap w-full space-y-4 md:space-y-0 md:space-x-4 md:w-auto mb-4 md:mb-0 md:mr-4 text-center">

					{/* Primary link */}
					{
						dynamicData?.data?.primary_link_text?.value &&
						<Button
							id={dynamicData?.uid + 'primary-link'}
							href={dynamicData?.data?.primary_link?.value}
							style={ButtonStyle.filled}
							size={ButtonSize.large}
							color={dynamicData?.data?.primary_link_color?.value}
							tint={dynamicData?.data?.primary_link_color_tint?.value}>

							{dynamicData?.data?.primary_link_text?.value}

						</Button>
					}

					{/* Secondary link */}
					{
						dynamicData?.data?.secondary_link_text?.value &&
						<Button
							id={dynamicData?.uid + 'secondary-link'}
							href={dynamicData?.data?.secondary_link?.value}
							style={ButtonStyle.outlined}
							size={ButtonSize.large}
							color={dynamicData?.data?.secondary_link_color?.value}
							tint={dynamicData?.data?.secondary_link_color_tint?.value}>

							{dynamicData?.data?.secondary_link_text?.value}

						</Button>
					}

				</div>

			</div>

		</section>

	)
}
