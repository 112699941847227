'use client';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { DsxImageClient } from "@/base-components/image";
import { Button, ButtonSize, ButtonStyle } from "@/ui-components/button";
import { DynamicProperty } from "@/utils/dynamic-properties";
import { getValue } from "@/utils/styles";
import { CSSVariables } from "@/utils/types";
import { getColorVariable } from "@/utils/color";
import FAIcon from "@/base-components/icons";
import 'swiper/css';
import Link from "next/link";

export default function EdgeSlider({ dynamicData, slides }: { dynamicData: any, slides: any })
{

	const section_paddings: CSSVariables = {
		[DynamicProperty.Padding.Top.Default]: getValue(dynamicData?.data?.padding_top?.value),
		[DynamicProperty.Padding.Bottom.Default]: getValue(dynamicData?.data?.padding_bottom?.value),
	}

	const section_overtitle_text_color: CSSVariables = {
		[DynamicProperty.Color.Text.Default]: getColorVariable(
			dynamicData?.data?.feature_link_color?.value,
			dynamicData?.data?.feature_link_color_tint?.value)
	}

	const section_title_text_color: CSSVariables = {
		[DynamicProperty.Color.Text.Default]: getColorVariable(
			dynamicData?.data?.section_text_color?.value,
			Number(dynamicData?.data?.section_text_color_tint?.value) + 300)
	}

	const section_text_color: CSSVariables = {
		[DynamicProperty.Color.Text.Default]: getColorVariable(
			dynamicData?.data?.section_text_color?.value,
			dynamicData?.data?.section_text_color_tint?.value)
	}

	return (
		slides?.length > 0 &&
		<div
			className={`pt-(--dynamic-pt) pb-(--dynamic-pb)`}
			style={{ ...section_paddings }}>

			<div className="static grid grid-cols-1 lg:grid-cols-3">

				<div className="lg:flex lg:flex-col justify-between mx-auto px-4 sm:px-6 lg:px-8">

					{/* Heading */}
					<div className="">

						{/* Overtitle */}
						{
							dynamicData?.data?.overtitle?.value &&
							<p
								className={`text-p5 text-(--dynamic-text) mb-4 w-full font-bold uppercase tracking-widest line-clamp-1`}
								style={{ ...section_overtitle_text_color }}>

								{dynamicData?.data?.overtitle?.value}

							</p>
						}

						{/* Title */}
						{
							dynamicData?.data?.title?.value &&
							<h2
								className={`text-(--dynamic-text) font-bold capitalize tracking-tight`}
								style={{ ...section_title_text_color }}>

								{dynamicData?.data?.title?.value}

							</h2>
						}

						{/* Description */}
						{
							dynamicData?.data?.description?.value &&
							<p
								className={`text-p2 text-(--dynamic-text)`}
								style={{ ...section_text_color }}>

								{dynamicData?.data?.description?.value}

							</p>
						}

					</div>

					{/* Buttons desktop */}
					<div className="hidden lg:mt-8 lg:flex leading-none">
						<button className="prev-button border border-primary-600 p-3 text-primary-600 hover:bg-primary-600 hover:text-white">

							<span className="sr-only">Previous Slide</span>
							<FAIcon type="solid" nameIco='fa-arrow-left' customClass="w-4 h-4" />

						</button>

						<button className="next-button border border-l-0 border-primary-600 p-3 text-primary-600 hover:bg-primary-600 hover:text-white">

							<span className="sr-only">Next Slide</span>
							<FAIcon type="solid" nameIco='fa-arrow-right' customClass="w-4 h-4" />

						</button>
					</div>

				</div>

				{/* Slider */}
				<div className="mt-8 lg:col-span-2 lg:mx-0 lg:mt-20">

					<Swiper
						navigation={{
							nextEl: '.next-button',
							prevEl: '.prev-button',
						}}
						modules={[Navigation]}
						spaceBetween={0}
						slidesPerView={1}
						breakpoints={{
							768: {
								slidesPerView: 1.5,
							},
						}}
						loop={true}>

						<div className="swiper-wrapper grid grid-cols-3">
							{
								slides?.map((slide: any, key: number) => 
								{
									return (
										<SwiperSlide key={key}>
											<blockquote className="flex flex-col md:flex-row bg-secondary-50 overflow-hidden mx-4 md:mx-0">

												{
													slide?.slide_image &&
													<Link
														href={`/blog/${slide?.slug}`}
														className="relative flex content-stretch w-full md:w-1/3 overflow-hidden">

														<DsxImageClient dsxImage={slide?.slide_image} />

													</Link>
												}

												<div className="flex flex-col items-start w-full md:w-2/3 p-4 lg:py-8 lg:pl-4 lg:pr-16">

													{/* Date */}
													<small className="mb-10 text-sm text-secondary-700">
														{
															slide?.publish_date &&
															new Date(slide?.publish_date).toLocaleDateString('en-US') || '—'
														}
													</small>

													<h3 className="mb-2 text-h4 font-bold line-clamp-2">
														<Link
															href={`/blog/${slide?.slug}`}
															className="transition duration-100 hover:text-indigo-500 active:text-indigo-600">

															{slide?.title}

														</Link>
													</h3>

													{/* Content */}
													<div
														className="mb-10 line-clamp-5 list-disc"
														dangerouslySetInnerHTML={{ __html: slide?.content ?? '' }}
													/>

													{/* Post link */}
													<Button
														id={slide?.id?.toString() + '-post_link' || slide?.slug || ''}
														href={`/blog/${slide?.slug}`}
														style={ButtonStyle.link}
														size={ButtonSize.regular}
														color={dynamicData?.data?.links_color?.value}
														tint={dynamicData?.data?.links_color_tint?.value}>

														Read more

													</Button>

												</div>

											</blockquote>
										</SwiperSlide>
									)
								})
							}

						</div>

					</Swiper>

				</div>

			</div>

			{/* Buttons mobile */}
			<div className="px-4 sm:px-6 mt-8 flex gap-4 lg:hidden">

				<button
					// onClick={() => swiper.slidePrev()}
					aria-label="Previous slide"
					className="prev-button border border-primary-600 p-4 text-primary-600 hover:bg-primary-600 hover:text-white">
					<FAIcon type="solid" nameIco='fa-arrow-left' customClass="w-4 h-4" />

				</button>

				<button
					// onClick={() => swiper.slideNext()}
					aria-label="Next slide"
					className="next-button border border-primary-600 p-4 text-primary-600 hover:bg-primary-600 hover:text-white">
					<FAIcon type="solid" nameIco='fa-arrow-right' customClass="w-4 h-4" />

				</button>

			</div>

		</div>
	)
}