'use client';

import { review, shop } from "@/types/graphql";
import { stdComponentDataList } from "@/utils/std-component-data";
import { useState } from "react";
import { getColorVariable } from "@/utils/color";
import { DynamicProperty } from "@/utils/dynamic-properties";
import { getValue } from "@/utils/styles";
import { CSSVariables } from "@/utils/types";
import Rating from "@/ui-components/rating";

//-->dynamicData
let dynamicData = {
	uid: 'page-reviews-default',
	adapter: "reviews",
	data: {

		"all_reviews": {
			sort: 1,
			name: 'Show All Reviews',
			type: 'checkbox',
			value: 'true',
			section: 'content',
		},
		"reviews_number": {
			sort: 2,
			name: 'Reviews Number',
			type: 'number',
			value: 128,
			min_num: 1,
			section: 'content'
		},

		// UI
		"section_bg_color": {
			sort: 1,
			name: 'Section Background Color',
			type: 'color',
			value: 'unset',
			section: 'ui',
		},
		"section_bg_color_tint": stdComponentDataList('slider', 2, 'Section Background Color Tint', 'Section Background Color Tint', '', 100, 'ui', 'tints'),

		"review_bg_color": {
			sort: 3,
			name: 'Review Background Color',
			type: 'color',
			value: 'unset',
			section: 'ui',
		},
		"review_bg_color_tint": stdComponentDataList('slider', 4, 'Review Background Color Tint', 'Review Background Color Tint', '', 50, 'ui', 'tints'),

		"shadow": {
			sort: 5,
			name: 'Shadow',
			type: 'checkbox',
			value: 'true',
			section: 'ui',
		},

		"padding_top": stdComponentDataList('slider', 6, 'Top Padding', 'Padding', '', 24),
		"padding_bottom": stdComponentDataList('slider', 7, 'Bottom Padding', 'Padding', '', 20),
	}
}
//<--dynamicData

export default function PageReviews({ data, config }: { data: shop, config: any })
{

	config?.uid && (dynamicData = config);

	const section_bg_color: CSSVariables = {
		[DynamicProperty.Color.Bg.Default]: getColorVariable(
			dynamicData?.data?.section_bg_color?.value,
			dynamicData?.data?.section_bg_color_tint?.value),
	}

	const review_bg_color: CSSVariables = {
		[DynamicProperty.Color.Bg.Default]: getColorVariable(
			dynamicData?.data?.review_bg_color?.value,
			dynamicData?.data?.review_bg_color_tint?.value),
	}

	const section_paddings: CSSVariables = {
		[DynamicProperty.Padding.Top.Default]: getValue(dynamicData?.data?.padding_top?.value),
		[DynamicProperty.Padding.Bottom.Default]: getValue(dynamicData?.data?.padding_bottom?.value),
	}

	const [state, setState] = useState('google');

	const av_reviews: review[] = data?.reviews?.filter((review: review) =>
	{
		return review.source_id === 1;
	})

	const google_reviews: review[] = data?.reviews?.filter((review: review) =>
	{
		return review.source_id === 2;
	})

	let reviews;

	switch (state)
	{
		case 'av': {
			reviews = av_reviews;
			break;
		}
		case 'google': {
			reviews = google_reviews;
			break;
		}
		default: {
			reviews = google_reviews;
			break;
		}
	}

	const shadow: any = dynamicData?.data?.shadow?.value === 'true' && 'shadow-sm'

	return (
		<section
			className={`bg-(--dynamic-bg) dark:bg-neutral-900 dark:text-neutral-200`}
			style={{ ...section_bg_color }}>

			<div
				className={`pt-(--dynamic-pt) pb-(--dynamic-pb) mx-auto max-w-(--breakpoint-xl) px-4 md:px-6 lg:px-8 xl:px-0`}
				style={{ ...section_paddings }}>

				<div className="flex flex-wrap justify-between items-center mb-8">

					{/* title */}
					<h1 className="font-bold capitalize tracking-tight dark:text-white">
						Reviews
					</h1>

					{/* Tab buttons */}
					<div className="flex leading-tight p-1 overflow-x-auto overflow-y-hidden rounded-full text-center border border-neutral-200 focus:outline-hidden">

						<button
							onClick={() => setState('google')}
							className={``}>
							<h2 className={state === 'google'
								? 'px-4 py-2 rounded-full bg-primary-500 text-white text-p4 capitalize'
								: 'px-4 py-2 text-p4 text-neutral-500 hover:text-primary-500 dark:text-white'}>
								Reviews from Google
							</h2>
						</button>

						<button
							onClick={() => setState('av')}
							className={``}>
							<h2 className={state === 'av'
								? 'px-4 py-2 rounded-full bg-primary-500 text-white text-p4 capitalize'
								: 'px-4 py-2 text-p4 text-neutral-500 hover:text-primary-500 dark:text-white'}>
								Certified by AutoVitals
							</h2>
						</button>

					</div>

				</div>

				<ul className="gap-x-8 gap-y-10 gap-8 columns-1 sm:columns-2 lg:columns-3 break-inside-avoid-column">
					{
						reviews?.map((review: review, key: number) => (
							<li
								key={key}
								className={`${shadow}
									bg-(--dynamic-bg) relative w-full mx-auto mb-12 rounded overflow-hidden break-inside-avoid dark:bg-neutral-800`}
								style={{ ...review_bg_color }}>

								<blockquote className={`px-6 pt-8 pb-5`}>

									{/* Review text */}
									<p className="text-p3 w-full line-clamp-5 dark:text-neutral-100">
										{review?.content ?? '—'}
									</p>

									{/* Reviewer */}
									{
										<div className={`flex justify-between items-end mt-4 w-full`}>

											<div className="mr-2">

												{/* Reviews */}
												{
													// Google review
													review.source_id === 2 &&
													<h3 className="google_review mb-2 text-p4 font-bold capitalize leading-snug line-clamp-1 dark:text-white">

														{/* Author */}
														{review?.author_name?.toLowerCase() ?? "Unknown Author"}

													</h3>
												}
												{
													// AV review
													review.source_id === 1 &&
													<div className="mb-4 leading-snug">

														<h3 className="av_review font-bold text-p4 capitalize line-clamp-1 dark:text-white">

															{/* Author */}
															{review?.author_name?.toLowerCase() ?? "Unknown Author"}

														</h3>

														<p className="line-clamp-2 text-p4">

															{/* Location */}
															{review?.location && (' from ' + review?.location)}

															{/* Car */}
															{review?.ymm && (' for servicing ' + review?.ymm)}

														</p>

													</div>

												}

												{/* Date */}
												<p className="text-p4 leading-none dark:text-neutral-200">
													{review?.date
														? ' on ' + new Date(review?.date).toLocaleDateString('en-US')
														: '—'}
												</p>

											</div>

											<Rating rating={review?.rating ?? 5} color={""} />

										</div>
									}

								</blockquote>

							</li>
						))
					}
				</ul>

			</div>

		</section>
	)
}