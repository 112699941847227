'use client';

import FAIcon from "@/base-components/icons";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { review, shop } from "@/types/graphql";
import { stdComponentDataList } from "@/utils/std-component-data";
import { useEffect, useState } from "react";
import 'swiper/css';
import Rating from "@/ui-components/rating";
import Link from "next/link";
import { DynamicProperty } from "@/utils/dynamic-properties";
import { getValue } from "@/utils/styles";
import { CSSVariables } from "@/utils/types";
import { getColorVariable } from "@/utils/color";

//-->dynamicData
let dynamicData = {
	uid: "legacy-reviews-left",
	adapter: "reviews",
	data: {

		// Content
		"title": {
			sort: 1,
			name: 'Title',
			type: 'text',
			value: 'Our Customers Love Us',
			section: 'content',
		},
		"subtitle": {
			sort: 2,
			name: 'Subtitle',
			type: 'text',
			value: 'Here is a few of their latest reviews',
			section: 'cotent',
		},
		"link_text": {
			sort: 3,
			name: 'Link Text',
			type: 'text',
			value: 'Read Our Customers Reviews',
			section: 'content',
		},
		"link": {
			sort: 4,
			name: 'Link',
			type: 'text',
			value: 'reviews',
			section: 'content',
		},
		'reviews_number': {
			sort: 5,
			name: 'Reviews Number',
			type: 'number',
			value: 4,
			min_num: 1,
			max_num: 16,
			section: 'content'
		},
		'delay': {
			sort: 6,
			name: 'Delay',
			type: 'number',
			value: 12,
			min_num: 4,
			max_num: 32,
			section: 'content'
		},

		// UI
		"quotation_color": {
			sort: 1,
			name: 'Quotation Color',
			type: 'color',
			value: 'primary',
			section: 'ui',
		},
		"quotation_color_tint": stdComponentDataList('slider', 2, 'Quotation Color Tint', 'Quotation Color Tint', '', 500, 'ui', 'tints'),

		"quotation_height": stdComponentDataList('slider', 3, 'Quotation Height', 'Quotation Height', '', 32),

		"padding_top": stdComponentDataList('slider', 4, 'Top Padding', 'Top Padding', '', 16),
		"padding_bottom": stdComponentDataList('slider', 5, 'Bottom Padding', 'Top Padding', '', 12),
	},
};
//<--dynamicData

export default function Reviews({ data, config }: { data: shop, config: any })
{

	config?.uid && (dynamicData = config);

	const quotation_color: CSSVariables = {
		[DynamicProperty.Color.Text.Default]: getColorVariable(
			dynamicData?.data?.quotation_color?.value,
			dynamicData?.data?.quotation_color_tint?.value),
	}

	const quotation_height: CSSVariables = {
		[DynamicProperty.Height.Default]: getValue(dynamicData?.data?.quotation_height?.value),
	}

	const section_paddings: CSSVariables = {
		[DynamicProperty.Padding.Top.Default]: getValue(dynamicData?.data?.padding_top?.value),
		[DynamicProperty.Padding.Bottom.Default]: getValue(dynamicData?.data?.padding_bottom?.value),
	}

	// Array of selected reviews
	const selected_reviews: review[] = data?.reviews?.filter((review: review) =>
	{
		return review?.selected;
	});

	const [random_reviews, setRandomReviews] = useState<review[]>()

	useEffect(() =>
	{
		setRandomReviews(data?.reviews?.sort(() => Math.random() - 0.5).filter((review: review) =>
		{
			return review.rating === 5;
		}).slice(0, dynamicData?.data?.reviews_number?.value));
	}, [data]);

	const reviews: review[] = selected_reviews && selected_reviews?.length > 0
		? selected_reviews
		: random_reviews && random_reviews?.length > 0
			? random_reviews
			: []

	return (
		<section
			id={dynamicData?.uid}
			className={`pt-(--dynamic-pt) pb-(--dynamic-pb) relative z-10 bg-white mx-4 -mt-12 -mb-12 px-4 sm:px-8 lg:px-12 md:mx-auto max-w-(--breakpoint-sm) lg:max-w-(--breakpoint-xl) rounded shadow-xl`}
			style={{ ...section_paddings }}>

			<div className="grid grid-cols-1 lg:grid-cols-3 gap-6">

				{/* Title */}
				<div className="col-span-1">

					<h2 className="font-bold mb-4 capitalize tracking-tight">
						{dynamicData?.data?.title?.value}
					</h2>

					<p className="text-p2 mb-4 lg:mb-32">
						{dynamicData?.data?.subtitle?.value}
					</p>

					{/* Link */}
					<div className="flex flex-wrap gap-4 text-center">
						<Link
							href={dynamicData?.data?.link?.value ?? '/reviews'}
							className="inline-flex items-center w-full font-medium text-primary-500 hover:text-primary-700 focus:outline-hidden focus:ring-3 active:text-primary-500 sm:w-auto cursor-pointer">

							{dynamicData?.data?.link_text?.value ?? 'Read Our Customers Reviews'}

							<FAIcon type="solid" nameIco='fa-arrow-right' customClass="text-primary-500 ml-2 w-4 h-4" />

						</Link>
					</div>

				</div>

				{/* Slider */}
				<div className="lg:col-span-2">

					{/* Blockquote icon */}
					<FAIcon
						type="solid"
						nameIco='fa-quote-left'
						customClass={`text-(--dynamic-text) h-(--dynamic-h) leading-none lg:-mt-4 lg:mb-2`}
						style={{ ...quotation_height, ...quotation_color }}
					/>

					<Swiper
						className="lg:h-3/5"
						autoplay={{
							disableOnInteraction: false,
							delay: (dynamicData?.data?.delay?.value && dynamicData?.data?.delay?.value > 0
								? (dynamicData?.data?.delay?.value ?? 12) * 1000
								: 1000),
							pauseOnMouseEnter: true,
						}}
						modules={[Autoplay]}
						spaceBetween={16}
						slidesPerView={1}
						breakpoints={{
						}}
						loop={true}>

						{/* Reviews */}
						<div className="swiper-wrapper relative">
							{
								reviews?.map((review: review, key: number) =>
								{
									return (
										<SwiperSlide key={key}>
											<blockquote
												className="flex flex-col justify-between h-full"
												key={key}>

												{/* Review text */}
												<p className="w-full line-clamp-4">
													{review?.content ?? '—'}
												</p>

												<div className="flex flex-col lg:flex-row mt-4 w-full">

													<Rating rating={review?.rating ?? 5} color={""} />

													{/* Author and Location */}
													<h3 className="text-p3 font-bold ml-auto capitalize">

														{'— ' + (review?.author_name?.toLowerCase() || '— Unknown Author')}

														<span className="font-normal">
															{review?.location && (' from ' + review?.location)}
														</span>

													</h3>

												</div>

											</blockquote>
										</SwiperSlide>
									)
								})
							}
						</div>

					</Swiper>

				</div>

			</div>

		</section>
	)
}
