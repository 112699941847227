'use client';

import { review, shop } from "@/types/graphql";
import { getColorVariable } from "@/utils/color";
import { stdComponentDataList } from "@/utils/std-component-data";
import { CSSVariables } from "@/utils/types";
import { useEffect, useState } from "react";
import { DynamicProperty } from "@/utils/dynamic-properties";
import { getValue } from "@/utils/styles";
import { Button, ButtonSize, ButtonStyle } from "@/ui-components/button";
import Rating from "@/ui-components/rating";

//-->dynamicData
let dynamicData = {
	uid: "legacy-template-6-reviews",
	adapter: "reviews",
	data: {

		// Content
		"title": {
			sort: 1,
			name: 'Title',
			type: 'text',
			value: 'Our Reviews',
			section: 'content',
		},
		"reviews_number": {
			sort: 2,
			name: 'Reviews Number',
			type: 'radio',
			value: 3,
			options: [
				{
					'display': "3",
					'value': 3,
				},
				{
					'display': "6",
					'value': 6,
				},
			],
			section: 'content'
		},

		"primary_link_text": {
			sort: 3,
			name: 'Primary Link Text',
			type: 'text',
			value: 'Request Appointment',
			section: 'content',
		},
		"primary_link": {
			sort: 4,
			name: 'Primary Link',
			type: 'text',
			value: '#make-appointment',
			section: 'content',
		},
		"secondary_link_text": {
			sort: 5,
			name: 'Secondary Link Text',
			type: 'text',
			value: 'More From Our Customers',
			section: 'content',
		},
		"secondary_link": {
			sort: 6,
			name: 'Secondary Link',
			type: 'text',
			value: 'reviews',
			section: 'content',
		},

		// UI
		"section_bg_color": {
			sort: 1,
			name: 'Section Background Color',
			type: 'color',
			value: 'primary',
			section: 'ui',
		},
		"section_bg_color_tint": stdComponentDataList('slider', 2, 'Section Background Color Tint', 'Section Background Color Tint', '', 500, 'ui', 'tints'),
		"section_title_color": {
			sort: 3,
			name: 'Section Title Color',
			type: 'color',
			value: 'unset',
			section: 'ui',
		},
		"section_title_color_tint": stdComponentDataList('slider', 4, 'Section Title Color Tint', 'Section Title Color Tint', '', 500, 'ui', 'tints'),

		"skew": {
			sort: 5,
			name: 'Skew',
			type: 'checkbox',
			value: 'true',
			section: 'ui',
		},

		"review_bg_color": {
			sort: 6,
			name: 'Review Background Color',
			type: 'color',
			value: 'unset',
			section: 'ui',
		},
		"review_bg_color_tint": stdComponentDataList('slider', 7, 'Review Background Color Tint', 'Review Background Color Tint', '', 50, 'ui', 'tints'),
		"review_text_color": {
			sort: 8,
			name: 'Review Text Color',
			type: 'color',
			value: 'neutral',
			section: 'ui',
		},
		"review_text_color_tint": stdComponentDataList('slider', 9, 'Review Text Color Tint', 'Review Text Color Tint', '', 600, 'ui', 'tints'),
		"review_shadow": {
			sort: 10,
			name: 'Review Shadow',
			type: 'checkbox',
			value: 'false',
			section: 'ui',
		},

		"links_color": {
			sort: 11,
			name: 'Link Color',
			type: 'color',
			value: 'unset',
			section: 'ui',
		},
		"links_color_tint": stdComponentDataList('slider', 12, 'Links Color Tint', 'Links Color Tint', '', 500, 'ui', 'tints'),

		"padding_top": stdComponentDataList('slider', 13, 'Top Padding', 'Top Padding', '', 24),
		"padding_bottom": stdComponentDataList('slider', 14, 'Bottom Padding', 'Bottom Padding', '', 20),
	},
};
//<--dynamicData

export default function Reviews({ data, config }: { data: shop, config: any })
{

	config?.uid && (dynamicData = config);

	const section_bg_color: CSSVariables = {
		[DynamicProperty.Color.Bg.Default]: getColorVariable(
			dynamicData?.data?.section_bg_color?.value,
			dynamicData?.data?.section_bg_color_tint?.value),
	}

	const section_title_color: CSSVariables = {
		[DynamicProperty.Color.Text.Default]: getColorVariable(
			dynamicData?.data?.section_title_color?.value,
			dynamicData?.data?.section_title_color_tint?.value)
	}

	const review_bg_color: CSSVariables = {
		[DynamicProperty.Color.Bg.Default]: getColorVariable(
			dynamicData?.data?.review_bg_color?.value,
			dynamicData?.data?.review_bg_color_tint?.value),
	}

	const review_text_color: CSSVariables = {
		[DynamicProperty.Color.Text.Default]: getColorVariable(
			dynamicData?.data?.review_text_color?.value,
			dynamicData?.data?.review_text_color_tint?.value)
	}

	const section_paddings: CSSVariables = {
		[DynamicProperty.Padding.Top.Default]: getValue(dynamicData?.data?.padding_top?.value),
		[DynamicProperty.Padding.Bottom.Default]: getValue(dynamicData?.data?.padding_bottom?.value),
	}

	const skew = dynamicData?.data?.skew?.value === 'true' && "-skew-x-[14deg] transform-gpu";
	const unskew = dynamicData?.data?.skew?.value === 'true' && "skew-x-[14deg] transform-gpu";

	// Array of selected reviews
	const selected_reviews: review[] = data?.reviews?.filter((review: review) =>
	{
		return review.selected;
	});

	// Array with random Google reviews with 5* rating. Used by default
	const [random_google_reviews, setRandomGoogleReviews] = useState<review[]>()
	useEffect(() =>
	{
		setRandomGoogleReviews(data?.reviews?.sort(() => Math.random() - 0.5).filter((review: review) =>
		{
			return review.rating === 5 && review.source_id === 1;
		}).slice(0, dynamicData?.data?.reviews_number?.value));
	}, [data]);

	// Array with random AV reviews with 5* rating. Used in case shop has no Google reviews 
	const [random_av_reviews, setAVReviews] = useState<review[]>()
	useEffect(() =>
	{
		setAVReviews(data?.reviews?.sort(() => Math.random() - 0.5).filter((review: review) =>
		{
			return review.rating === 5 && review.source_id === 2;
		}).slice(0, dynamicData?.data?.reviews_number?.value));
	}, [data]);

	const reviews: review[] = selected_reviews && selected_reviews?.length > 0
		?
		selected_reviews
		:
		random_google_reviews && random_google_reviews?.length > 0
			?
			random_google_reviews
			:
			random_av_reviews && random_av_reviews?.length > 0
				?
				random_av_reviews
				:
				[]

	return (
		<section
			id={dynamicData?.uid}
			className={`bg-(--dynamic-bg) overflow-hidden`}
			style={{ ...section_bg_color }}>

			<div
				className={`pt-(--dynamic-pt) pb-(--dynamic-pb) mx-auto max-w-(--breakpoint-xl) px-4 md:px-6 lg:px-8 xl:px-0`}
				style={{ ...section_paddings }}>

				{/* Header */}
				<div className={`flex flex-wrap xl:flex-nowrap justify-between w-full`}>

					{/* Content */}
					<div className="max-w-(--breakpoint-md) space-y-4 pb-8">

						{/* Title */}
						{
							dynamicData?.data?.title?.value &&
							<h2
								className={`text-(--dynamic-text) font-bold capitalize italic tracking-tight`}
								style={{ ...section_title_color }}>

								{dynamicData?.data?.title?.value}

							</h2>
						}

					</div>

					{/* Links */}
					<div className={`flex flex-wrap md:flex-nowrap items-start w-full lg:w-auto mx-4 md:mx-0 space-y-4 md:space-y-0 md:space-x-4`}>

						{/* Primary link */}
						{
							dynamicData?.data?.primary_link_text?.value &&
							<div className={`${skew}
							ml-4 lg:ml-2`}>
								<Button
									id={'primary-link'}
									href={dynamicData?.data?.primary_link?.value || '#make-appointment'}
									style={ButtonStyle.filled}
									size={ButtonSize.regular}
									color={dynamicData?.data?.links_color?.value}
									tint={dynamicData?.data?.links_color_tint?.value}>

									{dynamicData?.data?.primary_link_text?.value || 'Request Appointment'}

								</Button>
							</div>
						}

						{/* Secondary link */}
						{
							dynamicData?.data?.secondary_link_text?.value &&
							<div className={`${skew}
							ml-4 lg:ml-2`}>
								<Button
									id={'primary-link'}
									href={dynamicData?.data?.secondary_link?.value || '#make-appointment'}
									style={ButtonStyle.outlined}
									size={ButtonSize.regular}
									color={dynamicData?.data?.links_color?.value}
									tint={dynamicData?.data?.links_color_tint?.value}>

									{dynamicData?.data?.secondary_link_text?.value || 'Request Appointment'}

								</Button>
							</div>
						}

					</div>

				</div>

				{/* Reviews */}
				<ul className="grid gap-x-8 gap-y-12 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 py-12">
					{
						reviews?.map((review: review, key: number) => (
							<li
								key={key}
								className={`text-(--dynamic-text) relative flex flex-col justify-between h-full px-2 py-8 rounded`}
								style={{ ...review_text_color }}>

								{/* Background */}
								<div
									className={`${skew}
									absolute bg-(--dynamic-bg) flex inset-0 rounded`}
									style={{ ...review_bg_color }}
								/>

								{/* Shadow */}
								{
									dynamicData?.data?.review_shadow?.value === 'true' &&
									<div className={`${skew}
										absolute flex inset-0 rounded shadow hover:shadow-md duration-300 mix-blend-overlay`} />
								}

								<div className={`relative`}>

									<Rating rating={review?.rating || 5} color={''} className={`${skew} ml-12 mb-2`} />

									<div className="relative overflow-hidden">

										<style jsx global>
											{`
												.shape-custom-left {
													shape-outside: polygon(0% 0%, 100% 0%, 0% 100%);
													clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
												}
												.shape-custom-right {
													shape-outside: polygon(0% 100%, 100% 0%, 100% 100%);
													clip-path: polygon(0% 100%, 100% 0%, 100% 100%);
												}
											`}
										</style>

										<div className={`shape-custom-left relative float-left w-10 h-40`} />
										<div className={`shape-custom-right relative float-right w-10 h-40`} />
										<div className={`${skew}
											absolute w-11/12 h-1/4 bottom-0 bg-linear-to-t from-white to-transparent`} />

										<p className={`h-40 italic mb-4`}>

											{review?.content ?? '—'}

										</p>

									</div>


									<footer className={`capitalize text-right font-bold italic mr-10`}>

										{/* Author */}
										{review?.author_name?.toLowerCase() ?? "Unknown Author"}

										{/* Location */}
										{
											review?.location &&
											<span className="font-normal">

												{' from ' + review?.location}

											</span>
										}

									</footer>

								</div>

							</li>
						))
					}
				</ul>

			</div>

		</section>

	)
}
