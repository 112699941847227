'use client';

import { useRef, useState } from 'react'
import { getColorVariable } from "@/utils/color";
import { DynamicProperty } from "@/utils/dynamic-properties";
import { CSSVariables } from "@/utils/types";
import GoogleMap from 'google-maps-react-markers'
import Marker from "./marker";

const Map = ({ lat, lng, dynamicData }: { lat: number, lng: number, dynamicData?: any }) =>
{
  const mapRef = useRef(null)
  const [mapReady, setMapReady] = useState<boolean>(false)

  const marker_color: CSSVariables = {
    [DynamicProperty.Color.Text.Default]: getColorVariable(
      dynamicData?.data?.marker_color?.value,
      dynamicData?.data?.marker_color_tint?.value)
  }

  const className = 'text-(--dynamic-text) aspect-square w-auto '
    + dynamicData?.data?.marker_size?.value

  return (
    <>
      {
        (lat && lng) &&
        <GoogleMap
          apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}
          defaultCenter={{ lat: lat, lng: lng }}
          defaultZoom={Number(dynamicData?.data?.zoom_level?.value || 16)}>

          <Marker
            lat={lat}
            lng={lng}
            draggable={false}
            markerId={lat + " " + lng}
            className={className ?? 'text-red h-10 aspect-square w-auto'}
            style={marker_color}
          />

        </GoogleMap>
      }
    </>
  )
}

export default Map