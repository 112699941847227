'use client';

import { shop } from "@/types/graphql";
import { getColorVariable } from "@/utils/color";
import { getWorkHoursOpen } from "@/utils/date";
import { CSSVariables, MenuItem } from "@/utils/types";
import { useEffect, useRef, useState } from 'react';
import FAIcon from "@/base-components/icons";
import Link from "next/link";
import { DynamicProperty } from "@/utils/dynamic-properties";

export default function Navigation({ dynamicData, navItems, data }: { dynamicData: any, navItems: any, data: shop })
{

	const name: string | null | undefined = data?.info?.name;
	const city: string | null | undefined = data?.info?.city;
	const address: string | null | undefined = data?.info?.address;
	const phone: string | null | undefined = data?.info?.phone_number;
	const st: string | null | undefined = data?.info?.state;

	const [state, setState] = useState(false);
	const [dropdownState, setDropdownState] = useState<any>({ isActive: false, key: null });
	const hideTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

	const handleMouseEnter = (key: number) =>
	{
		// Clear hiding timer
		if (hideTimeoutRef.current)
		{
			clearTimeout(hideTimeoutRef.current);
			hideTimeoutRef.current = null;
		}
		setDropdownState({ isActive: true, key: key });
	};

	const handleMouseLeave = (key: number) =>
	{
		hideTimeoutRef.current = setTimeout(() =>
		{
			setDropdownState({ isActive: false, key: key });
		}, 200);
	};

	const color_alpha: string | undefined = data?.colors?.at(0)?.value?.match(/.{2}$/)?.toString();
	const nav_bg_opacity: string = dynamicData?.data?.nav_bg_opacity?.value ? `/${Number(dynamicData?.data?.nav_bg_opacity?.value) + 20}` : '';

	const nav_bg_color: CSSVariables = {
		[DynamicProperty.Color.Bg.Default]: getColorVariable(
			dynamicData?.data?.nav_bg_color?.value,
			Number(dynamicData?.data?.nav_bg_color_tint?.value) + 100),
		[DynamicProperty.Color.Bg.Lg]: getColorVariable(
			dynamicData?.data?.nav_bg_color?.value,
			Number(dynamicData?.data?.nav_bg_color_tint?.value) + 100) + nav_bg_opacity,
	}

	const nav_border_color: CSSVariables = {
		[DynamicProperty.Color.Border.Default]: getColorVariable(
			dynamicData?.data?.nav_bg_color?.value,
			dynamicData?.data?.nav_bg_color_tint?.value)
	}

	const nav_text_color: CSSVariables = {
		[DynamicProperty.Color.Text.Default]: getColorVariable(
			dynamicData?.data?.nav_text_color?.value,
			dynamicData?.data?.nav_text_color_tint?.value)
	}

	const backdrop_blur: any = (dynamicData?.data?.is_overlay?.value === 'true' || dynamicData?.data?.nav_bg_opacity?.value < 100) && 'lg:backdrop-blur-lg'

	// Close the menu on click outside it
	useEffect(() =>
	{
		document.onclick = (e) =>
		{
			const target = e.target as HTMLElement;
			if (!target.closest(".nav-menu") || (target.closest(".nav-menu") && target.tagName == 'A')) setDropdownState({ isActive: false, key: null });
		};
	}, [])

	return (
		<>

			{/* {
				state ? (
					<div
						className="z-0 fixed left-0 top-0 w-screen h-screen bg-black/20 backdrop-blur-sm"
						onClick={() => setState(false)
						}></div >
				) : ""
			} */}

			<div className={`flex w-full lg:w-auto justify-end`}>

				{/* Mobile burger */}
				<div className="absolute top-0 p-4">
					<button
						className={`${dynamicData?.data?.logo_height?.value ?? 'h-16'}
						text-(--dynamic-text)
						lg:hidden items-center`}
						style={{ ...nav_text_color }}
						aria-label="Main navigation"
						title="Main navigation"
						onClick={() => setState(!state)}>
						{
							state
								?
								<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 dark:text-white" viewBox="0 0 20 20" fill="currentColor">
									<path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
								</svg>
								:
								<svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 dark:text-white" viewBox="0 0 24 24" fill="currentColor">
									<path fillRule="evenodd" d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm8.25 5.25a.75.75 0 01.75-.75h8.25a.75.75 0 010 1.5H12a.75.75 0 01-.75-.75z" clipRule="evenodd" />
								</svg>
						}
					</button>
				</div>

				{/* Root navigation */}
				<div className={`
					${state
						? 'block'
						: 'hidden'
					}
					${color_alpha && color_alpha === '00'
						?
						'bg-white/60'
						:
						dynamicData.data?.nav_bg_color?.value
							?
							'bg-(--dynamic-bg)'
							:
							'border-(--dynamic-border)'
					}
					lg:bg-inherit
					text-(--dynamic-text) nav-menu w-full px-4 py-4 lg:px-0 lg:py-0 lg:block shadow-md lg:shadow-none dark:text-neutral-200`}
					style={{ ...nav_text_color, ...nav_bg_color, ...nav_border_color }}>

					<div className="gap-6">

						{/* Menu */}
						<ul className={`menu items-center space-y-6 lg:flex lg:space-x-8 lg:space-y-0`}>
							{
								navItems.map((item: any, key: number) =>
								{
									return (
										<li
											key={key}>
											{
												item?.isDropdown
													?
													<div
														onMouseEnter={() => handleMouseEnter(key)}
														onMouseLeave={() => handleMouseLeave(key)}
														className="flex items-center justify-between gap-2">

														{/* Root menu dropdown link */}
														<Link
															id={'navigation-link-' + key}
															href={item?.path ?? '/'}
															className={`${dynamicData?.data?.nav_text_style?.value ?? 'capitalize'}`}
															target={item?.isNewPage ? '_blank' : '_self'}>

															{item?.title}

														</Link>

														<button
															className={`duration-300`}
															onClick={() => setDropdownState({ isActive: !dropdownState.isActive, key: key })}
															aria-label="Dropdown menu">

															{/* Root menu dropdown icon */}
															{
																dropdownState.key == key && dropdownState.isActive
																	?
																	<FAIcon type="solid" nameIco="fa-chevron-up" customClass={`w-3 h-3`} />
																	:
																	<FAIcon type="solid" nameIco="fa-chevron-down" customClass={`w-3 h-3`} />
															}

														</button>

													</div>
													:
													<Link
														id={'navigation-link-' + key}
														href={item?.path ?? '/'}
														className={`${dynamicData?.data?.nav_text_style?.value ?? 'capitalize'}
														block`}
														target={item?.isNewPage ? '_blank' : '_self'}>

														{/* Root menu regular link */}
														{item?.title}

													</Link>
											}

											{/* Dropdown items */}
											{
												item?.isDropdown && dropdownState.key == key && dropdownState.isActive &&
												<div
													onMouseEnter={() => handleMouseEnter(key)}
													onMouseLeave={() => handleMouseLeave(key)}
													className={`
													${color_alpha && color_alpha === '00'
															?
															'bg-white/60'
															:
															dynamicData.data?.nav_bg_color?.value
																?
																// If navigation has background color only. 
																// Dropdown menu background color is one step darker than navigation background
																// Opacity set only for desktop since backdrop blur overlaps in mobile navigation
																`bg-(--dynamic-bg)`
																:
																// If navigation has border color. 
																// Dropdown item background color is the same with navigation border color
																'bg-white'}
													${backdrop_blur}
													overflow-hidden dropdown-items lg:absolute top-full z-20 py-8 lg:py-12 px-4 lg:px-0 inset-x-0 lg:w-full lg:shadow-md dark:bg-neutral-800`}
													style={{ ...nav_bg_color, ...nav_border_color }}>

													<ul className='max-w-screen-xl mx-auto items-top space-y-8 gap-6 lg:gap-8 columns-1 md:columns-3 break-inside-avoid-column'>
														{
															(item?.dropNavs && item?.dropNavs.length) && item?.dropNavs.map((nItem: MenuItem, key: number) =>
															{
																return (
																	<li
																		className="w-full break-inside-avoid"
																		key={key}>

																		{/* Dropdown section title */}
																		<h4
																			className={`${dynamicData?.data?.nav_text_style?.value ?? 'capitalize'}
																			text-(--dynamic-text)
																			mb-2 text-h6 font-bold capitalize dark:text-white`}
																			style={{ ...nav_text_color }}>

																			{nItem?.title}

																		</h4>

																		{/* Dropdown section links list */}
																		{
																			(nItem && nItem?.dropNavs && nItem?.dropNavs?.length > 0) &&
																			<ul className="space-y-2">
																				{
																					nItem?.dropNavs?.map((navItem: MenuItem, key: number) => (
																						<li key={key}>
																							<Link
																								href={navItem?.path ?? '/'}
																								className={`items-center capitalize font-normal not-italic`}
																								target={navItem?.isNewPage ? '_blank' : '_self'}>

																								{navItem?.title}

																							</Link>
																						</li>
																					))
																				}
																			</ul>
																		}
																	</li>
																)
															})
														}
													</ul>

												</div>
											}
										</li>
									)
								})
							}
						</ul>

						{/* Secondary button mobile */}
						{
							dynamicData?.data?.secondary_button_text?.value &&
							<div className="flex lg:hidden justify-center w-full">
								{/* <Link
									id={dynamicData.uid + '-secondary-button-mobile'}
									href={dynamicData?.data?.secondary_button_link?.value ?? '#make-appointment'}
									className={`${getColor(
										ColorProperty.text,
										dynamicData?.data?.secondary_button_color?.value || dynamicData?.data?.button_color?.value,
										500)} 
										relative inline-flex leading-none w-full md:w-auto justify-center items-center rounded-xs px-8 py-4 font-medium duration-300 focus:outline-none focus:ring sm:w-auto cursor-pointer`}>

									{dynamicData?.data?.secondary_button_text?.value}

								</Link> */}
							</div>
						}

						{/* Mobile contacts */}
						<div className={` 
							first-letter:flex flex-wrap justify-between space-y-4 lg:hidden`}>

							<hr className={`mb-6`} />

							<Link href={'tel:' + phone} rel="noopener noreferrer" className="lg:items-center items-top gap-2 flex w-full">
								<FAIcon type="solid" nameIco='fa-mobile-button' customClass="h-4 w-4" />
								<p className="hover:underline focus-visible:underline">
									{phone}
								</p>
							</Link>

							<Link href="#" rel="noopener noreferrer" className="lg:items-center items-top gap-2 flex w-full">
								<FAIcon type="solid" nameIco='fa-location-dot' customClass="h-4 w-4" />
								<p className="hover:underline focus-visible:underline">
									{address + ', ' + city + ', ' + st}
								</p>
							</Link>

							{/* Hours */}
							<div className="items-start gap-2 flex">

								<FAIcon type="solid" nameIco='fa-calendar' customClass="h-4 w-4" />

								<ul className="">
									{
										data?.work_hours &&
										getWorkHoursOpen(data?.work_hours).map((day: any, key: number) => (

											day.weekday_start == day.weekday_end
												?
												<li
													key={key}
													className="">

													<span className="font-medium">
														{day.weekday_start}&ensp;
													</span>
													<span className="">
														{day.time_start}
													</span>
													<span>&nbsp;—&nbsp;</span>
													<span className="">
														{day.time_end}&ensp;
													</span>
													<br />

												</li>
												:
												<li
													key={key}
													className="">

													<span className="font-medium">
														{day.weekday_start}&nbsp;—&nbsp;{day.weekday_end}&ensp;
													</span>
													<span className="">
														{day.time_start}
													</span>
													<span>&nbsp;—&nbsp;</span>
													<span className="">
														{day.time_end}&ensp;
													</span>

												</li>

										))
									}
								</ul>
							</div>

						</div>

					</div>

				</div>

			</div >

		</>
	)
}