import { work_hour } from "@/types/graphql";

export const getWeekday = (weekdayIndex: number) =>
{
  return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][weekdayIndex] || 'Noday';
}

export const getWeekdayShort = (weekdayIndex: number) =>
{
  return ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"][weekdayIndex] || 'Noday';
}

export const getTime = (minutes: number) =>
{

  const date = new Date();
  date.setHours(0)
  date.setMinutes(minutes)

  return date.toLocaleTimeString('en-US', { hour: "numeric", minute: "2-digit" }).replace(/ /g, '\u00A0')

}

export const getWorkHours = (week: work_hour[]) =>
{

  const work_hours_string: { weekday: string, open: boolean, start: string, end: string }[] = [];

  week?.map((day: work_hour) =>
  {
    if (day.weekday != null && day.open != null && day.start_minutes != null && day.end_minutes != null)
    {
      work_hours_string.push({
        weekday: getWeekday(day.weekday),
        open: day.open,
        start: getTime(day.start_minutes),
        end: getTime(day.end_minutes)
      })
    }
  });

  return work_hours_string;
}

export const getWorkHoursShort = (week: work_hour[]) =>
{

  const work_hours_string: { weekday: string, open: boolean, start: string, end: string }[] = [];

  week?.map((day: work_hour) =>
  {
    if (day.weekday != null && day.open != null && day.start_minutes != null && day.end_minutes != null)
    {
      work_hours_string.push({
        weekday: getWeekdayShort(day.weekday),
        open: day.open,
        start: getTime(day.start_minutes),
        end: getTime(day.end_minutes)
      })
    }
  });

  return work_hours_string;

}

export const getWorkHoursOpen = (week: work_hour[]) =>
{

  const work_hours_string: { weekday_start: string, weekday_end: string, time_start: string, time_end: string }[] = [];

  getWorkHours(week).map((day) =>
  {
    if (day.open && day.weekday != null)
    {
      if (work_hours_string.length == 0)
      {

        work_hours_string.push({
          weekday_start: day.weekday,
          weekday_end: day.weekday,
          time_start: day.start,
          time_end: day.end
        })

      }
      else
      {

        const last_element = work_hours_string.at(-1);
        if (last_element)
        {

          // Push a new work day in the array if the work hours arn't equal.
          if (last_element.time_start != day.start || last_element.time_end != day.end)
          {

            work_hours_string.push({
              weekday_start: day.weekday,
              weekday_end: day.weekday,
              time_start: day.start,
              time_end: day.end
            })

          } else
          {

            // Replace the last work day with the curent work day if the work hours are equal.
            last_element.weekday_end = day.weekday
          }

        }

      }
    }
  })

  return work_hours_string;
}

export const getWorkHoursOpenShort = (week: work_hour[]) =>
{

  const work_hours_string: { weekday_start: string, weekday_end: string, time_start: string, time_end: string }[] = [];

  getWorkHoursShort(week).map((day) =>
  {
    if (day.open && day.weekday != null)
    {
      if (work_hours_string.length == 0)
      {

        work_hours_string.push({
          weekday_start: day.weekday,
          weekday_end: day.weekday,
          time_start: day.start,
          time_end: day.end
        })

      }
      else
      {

        const last_element = work_hours_string.at(-1);
        if (last_element)
        {

          // Push a new work day in the array if the work hours arn't equal.
          if (last_element.time_start != day.start || last_element.time_end != day.end)
          {

            work_hours_string.push({
              weekday_start: day.weekday,
              weekday_end: day.weekday,
              time_start: day.start,
              time_end: day.end
            })

          }
          else
          {
            // Replace the last work day with the curent work day if the work hours are equal.
            last_element.weekday_end = day.weekday
          }

        }

      }
    }
  })

  return work_hours_string;

}

export const getShemaWorkHoursShort = (workHours: work_hour[]) =>
{
  // Helper function to convert minutes to HH:MM format
  const minutesToHHMM = (minutes: number) =>
  {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
  };

  // Map weekdays from numbers to short names
  const weekdays = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
  let formattedHours = [];
  let currentSegment = null;

  for (let i = 0; i < workHours.length; i++)
  {
    const day = workHours[i];
    if (day.weekday == null || day.open == null || day.start_minutes == null || day.end_minutes == null)
    {
      continue;
    }
    const dayName = weekdays[day.weekday];
    const time = day.open ? `${minutesToHHMM(day.start_minutes)}-${minutesToHHMM(day.end_minutes)}` : 'Closed';

    // Check if we should start a new segment
    if (!currentSegment || currentSegment.time !== time)
    {
      if (currentSegment)
      {
        formattedHours.push(currentSegment);
      }
      currentSegment = { startDay: dayName, endDay: dayName, time: time };
    } else
    {
      // Otherwise, extend the current segment
      currentSegment.endDay = dayName;
    }
  }

  // Add the last segment
  if (currentSegment)
  {
    formattedHours.push(currentSegment);
  }

  // Format the final string
  return formattedHours.map(segment =>
  {
    return segment.startDay === segment.endDay ?
      `${segment.startDay} ${segment.time}` :
      `${segment.startDay}-${segment.endDay} ${segment.time}`;
  }).join('; ');
}

export const getShemaWorkHoursFull = (workHours: work_hour[]) =>
{

  // Helper function to convert minutes to HH:MM format
  const minutesToHHMM = (minutesFromMidnight: number): string =>
  {
    // Ensure the input is within the range of a day
    const totalMinutesInDay = 24 * 60;
    minutesFromMidnight = minutesFromMidnight % totalMinutesInDay;

    // Calculate hours and minutes
    const hours = Math.floor(minutesFromMidnight / 60);
    const minutes = minutesFromMidnight % 60;

    // Format hours and minutes to ensure two digits (e.g., "08" instead of "8")
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    // Construct the time string
    const timeString = `${formattedHours}:${formattedMinutes}:00`;

    return timeString;
  };

  // Map weekdays from numbers to short names
  const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  let jsonStrs: string[] = [];

  for (let i = 0; i < workHours.length; i++)
  {
    const day = workHours[i];
    if (day.weekday == null || day.open == null || day.start_minutes == null || day.end_minutes == null)
    {
      continue;
    }
    if (!day.open)
    {
      continue;
    }
    const dayName = weekdays[day.weekday];
    const openTime = minutesToHHMM(day.start_minutes);
    const closeTime = minutesToHHMM(day.end_minutes);

    jsonStrs.push(`{
"@type": "OpeningHoursSpecification",
"dayOfWeek": "https://schema.org/${dayName}",
"opens": "${openTime}",
"closes": "${closeTime}"
}`);

  }

  return jsonStrs.join(',\n');

}