export namespace DynamicProperty
{
	export namespace Color
	{
		export enum Bg
		{
			'Default' = '--dynamic-bg',
			'Hover' = '--dynamic-bg-hover',
			'Sm' = '--dynamic-bg-sm',
			'Md' = '--dynamic-bg-md',
			'Lg' = '--dynamic-bg-lg',
			'Xl' = '--dynamic-bg-xl',
			'2Xl' = '--dynamic-bg-2xl',
		}

		export enum Text
		{
			'Default' = '--dynamic-text',
			'Hover' = '--dynamic-text-hover',
			'Sm' = '--dynamic-text-sm',
			'Md' = '--dynamic-text-md',
			'Lg' = '--dynamic-text-lg',
			'Xl' = '--dynamic-text-xl',
			'2Xl' = '--dynamic-text-2xl',
		}

		export enum Border
		{
			'Default' = '--dynamic-border',
			'Hover' = '--dynamic-border-hover',
			'Sm' = '--dynamic-border-sm',
			'Md' = '--dynamic-border-md',
			'Lg' = '--dynamic-border-lg',
			'Xl' = '--dynamic-border-xl',
			'2Xl' = '--dynamic-border-2xl',
		}

		export enum Gradient
		{
			'Default' = '--dynamic-gradient',
			'Hover' = '--dynamic-gradient-hover',
			'Sm' = '--dynamic-gradient-sm',
			'Md' = '--dynamic-gradient-md',
			'Lg' = '--dynamic-gradient-lg',
			'Xl' = '--dynamic-gradient-xl',
			'2Xl' = '--dynamic-gradient-2xl',
		}

	}

	export namespace Padding
	{
		export enum Top
		{
			'Default' = '--dynamic-pt',
			'Hover' = '--dynamic-pt-hover',
			'Sm' = '--dynamic-pt-sm',
			'Md' = '--dynamic-pt-md',
			'Lg' = '--dynamic-pt-lg',
			'Xl' = '--dynamic-pt-xl',
			'2Xl' = '--dynamic-pt-2xl',
		}

		export enum Right
		{
			'Default' = '--dynamic-pr',
			'Hover' = '--dynamic-pr-hover',
			'Sm' = '--dynamic-pr-sm',
			'Md' = '--dynamic-pr-md',
			'Lg' = '--dynamic-pr-lg',
			'Xl' = '--dynamic-pr-xl',
			'2Xl' = '--dynamic-pr-2xl',
		}

		export enum Bottom
		{
			'Default' = '--dynamic-pb',
			'Hover' = '--dynamic-pb-hover',
			'Sm' = '--dynamic-pb-sm',
			'Md' = '--dynamic-pb-md',
			'Lg' = '--dynamic-pb-lg',
			'Xl' = '--dynamic-pb-xl',
			'2Xl' = '--dynamic-pb-2xl',
		}

		export enum Left
		{
			'Default' = '--dynamic-pl',
			'Hover' = '--dynamic-pl-hover',
			'Sm' = '--dynamic-pl-sm',
			'Md' = '--dynamic-pl-md',
			'Lg' = '--dynamic-pl-lg',
			'Xl' = '--dynamic-pl-xl',
			'2Xl' = '--dynamic-pl-2xl',
		}
	}

	export namespace Margin
	{
		export enum Top
		{
			'Default' = '--dynamic-mt',
			'Hover' = '--dynamic-mt-hover',
			'Sm' = '--dynamic-mt-sm',
			'Md' = '--dynamic-mt-md',
			'Lg' = '--dynamic-mt-lg',
			'Xl' = '--dynamic-mt-xl',
			'2Xl' = '--dynamic-mt-2xl',
		}

		export enum Right
		{
			'Default' = '--dynamic-mr',
			'Hover' = '--dynamic-mr-hover',
			'Sm' = '--dynamic-mr-sm',
			'Md' = '--dynamic-mr-md',
			'Lg' = '--dynamic-mr-lg',
			'Xl' = '--dynamic-mr-xl',
			'2Xl' = '--dynamic-mr-2xl',
		}

		export enum Bottom
		{
			'Default' = '--dynamic-mb',
			'Hover' = '--dynamic-mb-hover',
			'Sm' = '--dynamic-mb-sm',
			'Md' = '--dynamic-mb-md',
			'Lg' = '--dynamic-mb-lg',
			'Xl' = '--dynamic-mb-xl',
			'2Xl' = '--dynamic-mb-2xl',
		}

		export enum Left
		{
			'Default' = '--dynamic-ml',
			'Hover' = '--dynamic-ml-hover',
			'Sm' = '--dynamic-ml-sm',
			'Md' = '--dynamic-ml-md',
			'Lg' = '--dynamic-ml-lg',
			'Xl' = '--dynamic-ml-xl',
			'2Xl' = '--dynamic-ml-2xl',
		}
	}

	export enum Width
	{
		'Default' = '--dynamic-w',
		'Hover' = '--dynamic-w-wover',
		'Sm' = '--dynamic-w-sm',
		'Md' = '--dynamic-w-md',
		'Lg' = '--dynamic-w-lg',
		'Xl' = '--dynamic-w-xl',
		'2Xl' = '--dynamic-w-2xl',
	}

	export enum Height
	{
		'Default' = '--dynamic-h',
		'Hover' = '--dynamic-h-hover',
		'Sm' = '--dynamic-h-sm',
		'Md' = '--dynamic-h-md',
		'Lg' = '--dynamic-h-lg',
		'Xl' = '--dynamic-h-xl',
		'2Xl' = '--dynamic-h-2xl',
	}

}
