'use client';

import { review } from "@/types/graphql";
import { stdComponentDataList } from "@/utils/std-component-data";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import { useEffect, useState } from 'react';
import { getColorVariable } from "@/utils/color";
import { Button, ButtonSize, ButtonStyle } from "@/ui-components/button";
import { DynamicProperty } from "@/utils/dynamic-properties";
import { CSSVariables } from "@/utils/types";
import { getValue } from "@/utils/styles";
import FAIcon from "@/base-components/icons";
import 'swiper/css';
import 'swiper/css/pagination';

//-->dynamicData
let dynamicData = {
	uid: 'legacy-reviews-carousel',
	adapter: "reviews",
	data: {

		// Content
		"title": {
			sort: 1,
			name: 'Title',
			type: 'text',
			value: 'What Our Customers Say About Us',
			section: 'content',
		},
		"link_text": {
			sort: 2,
			name: 'Link Text',
			type: 'text',
			value: 'Read Our Customers Reviews',
			section: 'content',
		},
		"link": {
			sort: 3,
			name: 'Link',
			type: 'text',
			value: '/reviews',
			section: 'content',
		},
		'reviews_number': {
			sort: 4,
			name: 'Reviews Number',
			type: 'number',
			value: 4,
			min_num: 1,
			max_num: 16,
			section: 'content'
		},
		'delay': {
			sort: 5,
			name: 'Delay',
			type: 'number',
			value: 12,
			min_num: 4,
			max_num: 32,
			section: 'content'
		},

		// UI
		"section_bg_color": {
			sort: 1,
			name: 'Section Background Color',
			type: 'color',
			value: 'unset',
			section: 'ui',
		},
		"section_bg_color_tint": stdComponentDataList('slider', 2, 'Section Background Color Tint', 'Section Background Color Tint', '', 500, 'ui', 'tints'),

		"link_color": {
			sort: 3,
			name: 'Link Color',
			type: 'color',
			value: 'primary',
			section: 'ui',
		},
		"link_color_tint": stdComponentDataList('slider', 4, 'Link Color Tint', 'Link Color Tint', '', 500, 'ui', 'tints'),

		"padding_top": stdComponentDataList('slider', 5, 'Top Padding', 'Padding', '', 24),
		"padding_bottom": stdComponentDataList('slider', 6, 'Bottom Padding', 'Padding', '', 20),
	},
}
//<--dynamicData

export default function Reviews({ data, config }: any)
{

	config?.uid && (dynamicData = config);

	const section_bg_color: CSSVariables = {
		[DynamicProperty.Color.Bg.Default]: getColorVariable(
			dynamicData?.data?.section_bg_color?.value,
			dynamicData?.data?.section_bg_color_tint?.value),
	}

	const section_paddings: CSSVariables = {
		[DynamicProperty.Padding.Top.Default]: getValue(dynamicData?.data?.padding_top?.value),
		[DynamicProperty.Padding.Bottom.Default]: getValue(dynamicData?.data?.padding_bottom?.value),
	}

	// Array of selected reviews
	const selected_reviews: review[] = data?.reviews?.filter((review: review) =>
	{
		return review?.selected;
	});

	const [random_reviews, setRandomReviews] = useState<review[]>()

	useEffect(() =>
	{
		setRandomReviews(data?.reviews?.sort(() => Math.random() - 0.5).filter((review: review) =>
		{
			return review.rating === 5;
		}).slice(0, dynamicData?.data?.reviews_number?.value));
	}, [data]);

	const reviews: review[] = selected_reviews && selected_reviews?.length > 0
		? selected_reviews
		: random_reviews && random_reviews?.length > 0
			? random_reviews
			: []

	return (
		<section
			id={dynamicData?.uid}
			className={`bg-(--dynamic-bg)`}
			style={{ ...section_bg_color }}>

			<div
				className={`pt-(--dynamic-pt) pb-(--dynamic-pb) px-4 sm:px-6 xl:px-0 mx-auto max-w-(--breakpoint-xl)`}
				style={{ ...section_paddings }}>

				{/* Title */}
				{
					dynamicData?.data?.title?.value &&
					<h2 className="mb-4 font-bold text-center capitalize tracking-tight">

						{dynamicData?.data?.title?.value}

					</h2>
				}

				<Swiper
					autoplay={{
						disableOnInteraction: false,
						delay: (dynamicData?.data?.delay?.value && dynamicData?.data?.delay?.value > 0
							? (dynamicData?.data?.delay?.value ?? 12) * 1000
							: 1000),
						pauseOnMouseEnter: true,
					}}
					modules={[Autoplay, Pagination]}
					pagination={{
						clickable: true,
					}}
					spaceBetween={0}
					slidesPerView={1}
					breakpoints={{
					}}
					loop={true}>

					<div className="swiper-wrapper relative">
						{
							reviews?.map((review: review, key: number) =>
							{
								return (
									<SwiperSlide key={key}>

										<div className="flex flex-col items-center justify-center mt-8">

											{/* Avatar */}
											<div className="flex items-center justify-center p-8 mb-4 rounded-full bg-neutral-200">
												<FAIcon type="solid" nameIco='fa-user' customClass="h-10 w-10" />
											</div>

											{/* Author */}
											<h3 className="text-h5 font-bold capitalize mb-4">
												{review?.author_name ?? 'Unknown Author'}
											</h3>

											{/* Review text */}
											<p className="text-center mx-auto max-w-3xl mb-12 line-clamp-3">
												{review?.content ?? '—'}
											</p>

										</div>

									</SwiperSlide>
								)
							})
						}
					</div>

				</Swiper>

				{/* Link */}
				<div className="mt-8 flex justify-center">
					<Button
						id={dynamicData?.uid + '-link'}
						href={dynamicData?.data?.link?.value || '/reviews'}
						style={ButtonStyle.filled}
						size={ButtonSize.regular}
						color={dynamicData?.data?.link_color?.value}
						tint={dynamicData?.data?.link_color_tint?.value}>

						{dynamicData?.data?.link_text?.value || 'Read Our Customers Reviews'}

						<FAIcon type="solid" nameIco='fa-arrow-right' customClass="w-4 h-4 ml-2" />

					</Button>
				</div>

			</div>

		</section>
	)
}