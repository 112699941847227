'use client';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { DsxImageClient } from "@/base-components/image";
import { processDynamicLink } from "@/utils/data";
import { Button, ButtonSize, ButtonStyle } from "./button";
import React from 'react';
import FAIcon from "@/base-components/icons";
import 'swiper/css';
import 'swiper/css/pagination';

export default function MainSlider({ dynamicData, slides }: { dynamicData: any, slides: any })
{

	const link_style: any = {
		'filled': ButtonStyle.filled,
		'outlined': ButtonStyle.outlined,
		'link': ButtonStyle.link,
		'text': ButtonStyle.text,
	}

	return (
		slides?.length === 1
			?
			<>
				<div className="static flex pt-32 md:pt-64 pb-24 md:pb-56 px-4 sm:px-6 lg:px-0 w-full">

					<div className="text-white mx-auto z-10 flex flex-col items-center text-center max-w-screen-xl">

						{/* Title */}
						{
							slides?.at(0)?.title &&
							<h1 className="text-white font-bold mb-4 capitalize">

								{slides?.at(0)?.title}

							</h1>
						}

						{/* Subtitle */}
						{
							slides?.at(0)?.subtitle &&
							<p className="mb-8 text-p1 max-w-lg relaxed">

								{slides?.at(0)?.subtitle}

							</p>
						}

						{/* Primary link */}
						{
							slides?.at(0)?.primary_link_text &&
							<div className="flex flex-wrap mb-4 sm:mb-0">
								<Button
									id={'primary-link'}
									href={processDynamicLink(slides?.at(0)?.primary_link) || '#make-appointment'}
									style={link_style[dynamicData?.data?.primary_link_style?.value] || ButtonStyle.outlined}
									size={ButtonSize.regular}
									color={dynamicData?.data?.primary_link_color?.value}
									tint={dynamicData?.data?.primary_link_color_tint?.value}>

									{slides?.at(0)?.primary_link_text}

								</Button>
							</div>
						}

						{/* Secondary link */}
						{
							slides?.at(0)?.secondary_link_text &&
							<Button
								id={'primary-link'}
								href={processDynamicLink(slides?.at(0)?.secondary_link) || '#make-appointment'}
								style={ButtonStyle.text}
								size={ButtonSize.regular}
								color={dynamicData?.data?.primary_link_color?.value}
								tint={dynamicData?.data?.primary_link_color_tint?.value}>

								{slides?.at(0)?.secondary_link_text}

							</Button>
						}
					</div>

					<DsxImageClient dsxImage={slides?.at(0)?.slide_image} />

				</div>
			</>
			:
			<>
				{/* Buttons desktop */}
				<div className="absolute top-1/2 w-full justify-between hidden lg:flex z-10">

					<button className="prev-button bg-white/30 rounded-r p-4 hover:bg-primary-600 duration-300">
						<span className="sr-only">Previous Slide</span>
						<FAIcon type="solid" nameIco='fa-chevron-left' customClass="h-4 w-4" />
					</button>

					<button className="next-button bg-white/30 rounded-l p-4 hover:bg-primary-600 hover:duration-300">
						<span className="sr-only">Next Slide</span>
						<FAIcon type="solid" nameIco='fa-chevron-right' customClass="h-4 w-4" />
					</button>

				</div>

				<Swiper
					autoplay={{
						disableOnInteraction: false,
						delay: ((dynamicData?.data?.delay?.value ?? 12) * 1000),
						pauseOnMouseEnter: true,
					}}
					navigation={{
						nextEl: '.next-button',
						prevEl: '.prev-button',
					}}
					modules={[Autoplay, Pagination, Navigation]}
					pagination={{
						clickable: true,
					}}
					spaceBetween={0}
					slidesPerView={1}
					breakpoints={{
					}}
					loop={true}>

					<div className="swiper-wrapper">
						{
							slides?.map((slide: any, idx: number) => (
								<SwiperSlide key={idx}>

									<div className="flex pt-32 md:pt-64 pb-24 md:pb-56 px-4 sm:px-6 lg:px-0 w-full">

										<div className="mx-auto z-10 flex flex-col items-center text-center max-w-screen-xl">

											{/* Title */}
											{
												idx === 0
													?
													slide?.title &&
													<h1 className="text-white mb-4 font-bold capitalize">

														{slide?.title}

													</h1>
													:
													slide?.title &&
													<h2 className="text-h1 text-white mb-4 font-bold capitalize">

														{slide?.title}

													</h2>
											}

											{/* Subtitle */}
											{
												slide?.subtitle &&
												<p className="mb-8 text-p1 max-w-lg relaxed">

													{slide?.subtitle}

												</p>
											}

											{/* Primary link */}
											{
												slide?.primary_link_text &&
												<div className="flex flex-wrap mb-4">
													<Button
														id={'primary-link'}
														href={processDynamicLink(slide?.primary_link) || '#make-appointment'}
														style={link_style[dynamicData?.data?.primary_link_style?.value] || ButtonStyle.outlined}
														size={ButtonSize.regular}
														color={dynamicData?.data?.primary_link_color?.value}
														tint={dynamicData?.data?.primary_link_color_tint?.value}>

														{slide?.primary_link_text}

													</Button>
												</div>
											}

											{/* Secondary link */}
											{
												slide?.secondary_link_text &&
												<Button
													id={'primary-link'}
													href={processDynamicLink(slide?.secondary_link) || '#make-appointment'}
													style={ButtonStyle.text}
													size={ButtonSize.regular}
													color={dynamicData?.data?.primary_link_color?.value}
													tint={dynamicData?.data?.primary_link_color_tint?.value}>

													{slide?.secondary_link_text}

												</Button>
											}

										</div>

										<DsxImageClient dsxImage={slide?.slide_image} />

									</div>

								</SwiperSlide>
							))
						}
					</div>

				</Swiper>

				<style jsx global>
					{`
						.swiper-pagination-bullet {
							background-color: white;
						}
						
						.swiper-pagination-bullet-active {
							background-color: var(--clr-primary-500);
						}

					`}
				</style>

			</>
	)
}
