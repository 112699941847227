'use client';

import { DsxImageClient } from "@/base-components/image";
import { shop } from "@/types/graphql";
import { Button, ButtonSize, ButtonStyle } from "@/ui-components/button";
import { getColorVariable } from "@/utils/color";
import { DynamicProperty } from "@/utils/dynamic-properties";
import { stdComponentDataList } from "@/utils/std-component-data";
import { getValue } from "@/utils/styles";
import { DsxImageType, CSSVariables } from "@/utils/types";
import { useEffect, useRef, useState } from "react";
import FAIcon from "@/base-components/icons";

//-->dynamicData
let dynamicData = {
  uid: 'banner-left-icon-button',
  adapter: "banner",
  data: {

    // UI
    "paddings": stdComponentDataList('slider', 1, 'Paddings', 'Padding', '', 4),
  }
}
//<--dynamicData

export default function Banner({ data, images, config }: { data: shop, images: DsxImageType, config: any })
{

  config?.uid && (dynamicData = config);

  const section_bg_color: CSSVariables = {
    [DynamicProperty.Color.Bg.Default]: getColorVariable(
      data?.info?.special_message_color || 'unset',
      500),
  }

  const special_message_text_color: CSSVariables = {
    [DynamicProperty.Color.Text.Default]: getColorVariable(
      data?.info?.special_message_text_color || 'unset',
      500)
  }

  const [isVisible, setIsVisible] = useState(true);
  const [height, setHeight] = useState<number | 'auto'>('auto');
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() =>
  {
    if (contentRef.current)
    {
      setHeight(isVisible ? contentRef.current.scrollHeight : 0);
    }
  }, [isVisible]);

  const handleClose = () =>
  {
    setIsVisible(false);
  };

  const section_paddings: CSSVariables = {
    [DynamicProperty.Padding.Top.Default]: getValue(dynamicData?.data?.paddings?.value),
    [DynamicProperty.Padding.Bottom.Default]: getValue(dynamicData?.data?.paddings?.value),
  }

  return (
    data?.info?.special_message_enabled && data?.info?.special_message_content &&
    <div
      style={{ height: typeof height === 'number' ? `${height}px` : height }}
      className={`overflow-hidden transition-[height] duration-300`}>

      <div
        ref={contentRef}
        className={`bg-(--dynamic-bg)`}
        style={{ ...section_bg_color }}>

        <div
          className={`pt-(--dynamic-pt) pb-(--dynamic-pb) flex flex-wrap md:flex-nowrap px-4 sm:px-6 xl:px-0 py-4 mx-auto max-w-(--breakpoint-xl) items-center justify-between`}
          style={{ ...section_paddings }}>

          <div className="flex items-center w-full mb-4 md:mb-0 space-x-2 md:mr-4">

            {/* Icon */}
            {
              data?.info?.special_message_image_id &&
              <div
                className={`text-(--dynamic-text)`}
                style={{ ...special_message_text_color }}>

                <DsxImageClient dsxImage={images} />

              </div>
            }

            {/* Content */}
            <div
              className="w-full line-clamp-3 md:line-clamp-2"
              dangerouslySetInnerHTML={{ __html: data?.info?.special_message_content ?? '' }}
            />

          </div>

          <div className="flex w-full md:w-auto justify-between items-center">

            {/* Link */}
            {
              (data?.info?.special_message_button_text && data?.info?.special_message_button_url) &&
              <div className={`ml-4 lg:ml-2`}>
                <Button
                  id={dynamicData?.uid + '-link'}
                  href={data?.info?.special_message_button_url || '/specials'}
                  style={ButtonStyle.filled}
                  size={ButtonSize.small}
                  color={data?.info?.special_message_button_color || 'unset'}
                  tint={500}>

                  {data?.info?.special_message_button_text}
                  <FAIcon type="solid" nameIco='fa-arrow-right' customClass="w-4 h-4 ml-2" />

                </Button>
              </div>
            }

            {
              data?.info?.special_message_display_close_button &&
              <button
                onClick={handleClose}
                aria-label="Close button"
                className={`text-(--dynamic-text) p-2 leading-none active:border-none active:outline-hidden`}
                style={{ ...special_message_text_color }}>

                <FAIcon type="solid" nameIco='fa-xmark' customClass="w-6 h-6" />

              </button>
            }

          </div>

        </div>

      </div>

    </div>
  )

}
