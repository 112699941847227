import { getColorVariable } from "@/utils/color";
import { DynamicProperty } from "@/utils/dynamic-properties";
import { CSSVariables } from "@/utils/types";
import Link from "next/link";

export enum ButtonType
{
	button = 'button',
	submit = 'submit',
	reset = 'reset',
}

export enum ButtonStyle
{
	filled = 'filled',
	outlined = 'outlined',
	link = 'link',
	text = 'text',
}

export enum ButtonSize
{
	small = 'small',
	regular = 'regular',
	large = 'large',
}

export enum ButtonTarget
{
	blank = '_blank',
	self = '_self',
	parent = '_parent',
	top = '_top',
	framename = 'framename',
}

interface ButtonProps
{
	id: string;
	href?: string;
	type?: ButtonType;
	style: ButtonStyle;
	size?: ButtonSize;
	target?: ButtonTarget;
	color: string;
	tint: number;
	children?: React.ReactNode;
	onClick?: () => void;
}

export function Button({ id, href, type = ButtonType.button, style, size = ButtonSize.regular, target = ButtonTarget.self, color, tint, children, onClick }: ButtonProps)
{

	tint = Number(tint);

	const _color = getColor(style);
	const _size = getSize(size, style);

	const className = [
		_color,
		_size,
		'inline-flex items-center justify-center text-center rounded font-medium whitespace-nowrap duration-300 focus:outline-none focus:ring cursor-pointer'
	].filter(Boolean).join(' ');

	return (
		href ?
			<Link
				id={id}
				href={href}
				target={target}
				className={className}
				onClick={onClick}
				style={{ ...getStyle(style, color, tint) }}>

				{children}

			</Link>
			:
			<button
				id={id}
				type={type}
				className={className}
				onClick={onClick}
				style={{ ...getStyle(style, color, tint) }}>

				{children}

			</button>
	)

	function getColor(style: ButtonStyle)
	{
		switch (style)
		{
			case ButtonStyle.filled:
				return 'bg-(--dynamic-bg) hover:bg-(--dynamic-bg-hover) text-(--dynamic-text) hover:text-(--dynamic-text-hover)';
			case ButtonStyle.outlined:
				return 'border-(--dynamic-border) hover:border-(--dynamic-border-hover) text-(--dynamic-text) hover:text-(--dynamic-text-hover) border';
			case ButtonStyle.link:
				return 'text-(--dynamic-text) hover:text-(--dynamic-text-hover) underline underline-offset-4 hover:underline-offset-8 duration-300';
			case ButtonStyle.text:
				return 'text-(--dynamic-text) hover:text-(--dynamic-text-hover) underline underline-offset-4 hover:underline-offset-8 duration-300';
			default:
				return 'bg-(--dynamic-bg) hover:bg-(--dynamic-bg-hover) text-(--dynamic-text)';
		}
	}

	function getStyle(style: ButtonStyle, color: string, tint: number): CSSVariables
	{
		switch (style)
		{
			case ButtonStyle.filled:
				return color !== 'unset'
					?
					{
						[DynamicProperty.Color.Bg.Default]: getColorVariable(color, tint),
						[DynamicProperty.Color.Bg.Hover]: getColorVariable(color, tint + 100),
						[DynamicProperty.Color.Text.Default]: getColorVariable('unset', 500),
						[DynamicProperty.Color.Text.Hover]: getColorVariable('unset', 500),
					}
					:
					{
						[DynamicProperty.Color.Bg.Default]: getColorVariable('unset', 500),
						[DynamicProperty.Color.Bg.Hover]: getColorVariable('unset', 500),
						[DynamicProperty.Color.Text.Default]: getColorVariable('primary', 500),
						[DynamicProperty.Color.Text.Hover]: getColorVariable('primary', 600),
					}
			case ButtonStyle.outlined:
				return {
					[DynamicProperty.Color.Border.Default]: getColorVariable(color, tint),
					[DynamicProperty.Color.Border.Hover]: getColorVariable(color, tint + 100),
					[DynamicProperty.Color.Text.Default]: getColorVariable(color, tint),
					[DynamicProperty.Color.Text.Hover]: getColorVariable(color, tint + 100),
				}
			case ButtonStyle.link:
				return {
					[DynamicProperty.Color.Text.Default]: getColorVariable(color, tint),
					[DynamicProperty.Color.Text.Hover]: getColorVariable(color, tint + 100),
				}
			case ButtonStyle.text:
				return {
					[DynamicProperty.Color.Text.Default]: getColorVariable(color, tint),
					[DynamicProperty.Color.Text.Hover]: getColorVariable(color, tint + 100),
				}
			default:
				return {
					[DynamicProperty.Color.Bg.Default]: getColorVariable('primary', 500),
					[DynamicProperty.Color.Bg.Hover]: getColorVariable('primary', 600),
					[DynamicProperty.Color.Text.Default]: getColorVariable('unset', 500),
					[DynamicProperty.Color.Text.Hover]: getColorVariable('unset', 500),
				}
		}
	}

	function getSize(size: ButtonSize, style: ButtonStyle)
	{
		const isLink = style === ButtonStyle.link

		switch (size)
		{
			case ButtonSize.small:
				return isLink ? 'text-p5' : 'px-6 py-2 text-p5';
			case ButtonSize.regular:
				return isLink ? '' : 'px-8 py-3';
			case ButtonSize.large:
				return isLink ? 'text-p3' : 'px-10 py-4 text-p3';
			default:
				return '';
		}
	}
}
