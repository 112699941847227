"use client";

import FAIcon from "@/base-components/icons";
import Image from 'next/image'
import { review } from "@/types/graphql";
import { stdComponentDataList } from "@/utils/std-component-data";
import { ShopColors } from "@/utils/types";
import { DsxImage } from "@/base-components/image";
import Link from "next/link";

//-->dynamicData
let dynamicData = {
	uid: 'reviews-glass-left-3-9-bkgimage',
	data: {

		// Content
		"title": {
			sort: 1,
			name: 'Title',
			type: 'text',
			value: 'What Our Customers Say About Us',
			section: 'content',
		},
		"button_text": {
			sort: 2,
			name: 'Button Text',
			type: 'text',
			value: 'Read Our Customers Reviews',
			section: 'content',
		},
		"button_link": {
			sort: 3,
			name: 'Button Link',
			type: 'text',
			value: '/reviews',
			section: 'content',
		},

		// UI
		"bg_color": {
			sort: 1,
			name: 'Background Color',
			type: 'color',
			value: 'unset',
			section: 'ui',
		},
		"paddings": stdComponentDataList('slider', 2, 'Top and Bottom Paddings', 'Padding', '', 24),
	},
}
//<--dynamicData

export default function Reviews({ data }: any)
{

	// Array of selected reviews
	let selected_reviews = data?.reviews?.filter((review: review) =>
	{
		return review.selected;
	});

	const color_value: string = dynamicData?.data?.bg_color?.value

	const bg_color: ShopColors = {
		primary: 'bg-primary-600 text-primary-50',
		secondary: 'bg-secondary-700 text-secondary-50',
		neutral: 'bg-neutral-900 text-neutral-50',
		unset: 'bg-white text-neutral-800'
	}

	const divider_color: ShopColors = {
		primary: 'bg-primary-50',
		secondary: 'bg-secondary-50',
		neutral: 'bg-neutral-50',
		unset: 'bg-primary-500'
	}

	const controls_color: ShopColors = {
		primary: 'border-primary-50 hover:bg-primary-500',
		secondary: 'border-neutral-50 hover:bg-neutral-600',
		neutral: 'border-neutral-50 hover:bg-neutral-700',
		unset: 'border-neutral-600 hover:bg-neutral-100'
	}

	return (
		<section className={bg_color[color_value as keyof ShopColors]}>

			<div className={dynamicData?.data?.paddings?.value
				? `py-${dynamicData?.data?.paddings?.value}`
				: 'py-24'}>

				{/* Title */}
				<h2 className="mb-4 font-bold text-center capitalize">
					{dynamicData?.data?.title?.value
						? dynamicData?.data?.title?.value
						: ''}
				</h2>

				{/* Divider */}
				<div className="flex mb-16 justify-center">
					<div className={`${divider_color[color_value as keyof ShopColors]} w-16 h-1 rounded-full inline-flex`}></div>
				</div>

				{/* Reviews */}
				<div className="relative container mb-16 mx-auto">
					<div
						className="px-6 py-28 mx-auto"
						style={{ scrollSnapType: "x mandatory" }}>
						{
							selected_reviews?.map((review: any, index: number) =>
							{
								return (
									<div key={index}>

										<input
											defaultChecked={index == 0 ? true : false}
											className="sr-only peer"
											type="radio"
											name="carousel"
											id={'carousel-' + index}
											aria-label={`Review #${index}`}
										/>

										<div className="absolute w-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-all duration-300 peer-checked:opacity-100 opacity-0 peer-checked:z-10 z-0">

											<p className="text-center mx-auto max-w-3xl">
												{review?.content
													? review?.content
													: '—'}
											</p>

											<div className="flex flex-col items-center justify-center mt-8">
												<div className="w-14 h-14 relative">

													{/* Avatar */}
													<DsxImage
														fill={true}
														className="object-cover rounded-full w-14 h-14"
														data="https://images.unsplash.com/photo-1499470932971-a90681ce8530"
														alt={review?.author_name
															? "— " + review?.author_name
															: "— Unknown Author"}
														priority={false}
													/>

												</div>
												<div className="mt-4 text-center">

													{/* Author */}
													<h4 className="font-bold">
														{review?.author_name
															? "— " + review?.author_name
															: "— Unknown Author"}
													</h4>

													{/* Date */}
													<small className="">
														{review.date
															? new Date(review.date).toDateString()
															: '—'}
													</small>

												</div>
											</div>

											{/* Controls */}
											<div className="absolute top-1/2 w-full flex justify-between z-20">

												<label
													htmlFor={'carousel-' + (index + 1)}
													title="left arrow"
													className={`${controls_color[color_value as keyof ShopColors]} w-12 h-12 flex items-center justify-center transition duration-300 border rounded-full rtl:-scale-x-100 hover:bg-neutral-100 cursor-pointer`}>
													<FAIcon type="solid" nameIco='fa-chevron-left' customClass="h-4 w-4" />
												</label>

												<label
													htmlFor={'carousel-' + (index - 1)}
													title="right arrow"
													className={`${controls_color[color_value as keyof ShopColors]} w-12 h-12 flex items-center justify-center transition duration-300 border rounded-full rtl:-scale-x-100 hover:bg-neutral-100 cursor-pointer`}>
													<FAIcon type="solid" nameIco='fa-chevron-right' customClass="h-4 w-4" />
												</label>

											</div>

										</div>
									</div>
								)
							})

						}
					</div>
				</div>

				{/* CTA */}
				<div className="mt-8 flex flex-wrap text-center">
					<Link
						className="mx-auto inline-flex items-center rounded-lg bg-primary-500 px-8 py-3 font-medium text-white hover:bg-primary-600 focus:outline-hidden focus:ring-3 active:bg-primary-500 cursor-pointer"

						href={dynamicData?.data?.button_link?.value
							? dynamicData?.data?.button_link?.value
							: '/reviews'}>

						{dynamicData?.data?.button_text?.value
							? dynamicData?.data?.button_text?.value
							: 'Read Our Customers Reviews'}

						<FAIcon type="solid" nameIco='fa-arrow-right' customClass="h-4 text-white ml-2" />
					</Link>
				</div>

			</div>

		</section>
	)
}